import zhtLocale from 'element-ui/lib/locale/lang/zh-TW'

const lang = {
  ...zhtLocale,
  common: {
    alertPrompt: '提醒',
    requestCheckConnected: '服務器繁忙，請稍後重試！',
    updateInstruction: '更新説明',
    operation: '操作',
    yes: '是',
    no: '否',
    sort: '排序',
    copy: '複製',
    confirm: '確認',
    cancel: '取消',
    save: '儲存',
    detail: '詳細內容',
    viewDetail: '檢視內容',
    add: '新增',
    create: '新增',
    import: '導入',
    view: '檢視',
    set: '設定',
    delete: '刪除',
    deleteAll: '全部刪除',
    publish: '發布',
    preview: '預覽',
    imageUploadTip: '圖片大小應在{size}以內',
    attahmentUploadTip: '附件大小應在{size}以內',
    photos: '照片',
    select: '選擇',
    clear: '清除',
    back: '返回',
    stop: '停止',
    edit: '編輯',
    change: '更換',
    start: '開始',
    hour: '小時',
    minute: '分鐘',
    day: '天',
    noSupportTips: '目前方案不支援該功能，如需使用請升級方案，有任何疑問請聯絡【Line 官方帳號】客服人員。',
    valid: '有效',
    inValid: '無效',
    timeOut: '逾時',
    enable: '啟動',
    close: '關閉',
    all: '全部',
    to: '到',
    more: '更多',
    send: '傳送',
    ok: '好的',
    submit: '提交',
    copyrightDesc: 'Copyright © 2024 SAMEX 賽美斯軟件系統開發有限公司版權所有',
    seleted: '已選擇',
    pleaseSelect: '請選擇',
    startTime: '開始時間',
    endTime: '結束時間',
    timeTo: '至',
    pageTotal: '共有',
    pageTotalRecord: '條記錄',
    pageTotalRecords: '條記錄',
    nightMode: '黑色主題',
    noData: '無資料',

    // 簡訊錯誤信息、发送驗證碼失敗的提示
    ERR_NOTICE_0000: '傳送失敗',
    ERR_NOTICE_0001: ' 為無效號碼',
    ERR_NOTICE_0002: '運營商限流',
    CFG_NOTICE_0003: '沒有足夠的簡訊包數量或未開啟簡訊通知',
    CFG_NOTICE_0004: '沒有設置所需的區號或電話號碼',
    CFG_NOTICE_0005: '未開通傳送區域權限',
  },
  guideBox: {
    nextStep: '下一步',
    preStep: '上一步',
    androidDownload: 'Android版下載',
    iosDownload: 'iOS版下載 ',
    guideFinish: '完成',
    guideAccountRepeatedly: '是否繼續新增帳號',
    guidePointSrepeatedly: '是否繼續新增巡檢點',
  },
  webLayout: {
    webSiteHome: '網站首頁',
    locCountLabel: '個巡檢點',
    cardExpireDateLabel: '有效期至',
    onLineService: '線上客服',
    help: '幫助',
    releaseHistory: '版本歷史記錄',
    showGuideName: '顯示導覽',
    ViewTutorialVideos: '查看教程視頻',
    logOut: '登出',
    navHome: '首頁',
    navInspection: '巡檢監控',
    navSafetyIncident: '安全事件',
    navProperty: '物業實體',
    navAccount: '帳號資料',
    navNotification: '通知管理',
    navApp: 'APP下載',
    navSystem: '系統設定',
    navSSO: 'SSO設定',
    navCommonReasonManagement: '常用理由管理',
    navSla: 'SLA 設定',
    navVisitorQuestionTmpl: '訪客問題模板',
    vipExperience: '體驗卡',
    vipGreen: '綠卡',
    vipSilver: '銀卡',
    vipGold: '金卡',
    androidDownload: 'Android版下載',
    iosDownload: 'iOS版下載',

    collapseMenu: '摺叠菜單',
    propertyEntity: '物業實體',
    entityDetail: '基本資料',
    userAccounts: '使用者帳號',
    safetyGuard: '安全衛士',
    authorizationManagement: '授權管理',
    workGroups: '工作組',
    accessGroups: '使用者群組權限',
    inspectionPoints: '巡檢點',
    holidayManagement: '假期管理',
    assetLedger: '資產台帳',
    iotSensors: '物聯網傳感器',
    inspectionPlan: '巡檢計劃',
    createInspectionPlan: '新增巡檢計劃',
    inspectionPlanManagement: '巡檢計劃管理',
    sopManagement: '常用選項管理',
    workOrderTemplate: '工單模板',
    workOrderTemplateManagement: '工單模板管理',
    punchInTemplate: '打卡模板',
    signInTemplateManagement: '打卡簽到模板管理',
    monitorTemplate: '監控模板',
    monitorTemplateManagement: '監控模板管理',
    historicalRecords: '歷史記錄',
    inspectionOrderRecords: '巡檢單記錄',
    workOrderRecords: '工單記錄',
    signInRecord: '打卡簽到記錄',
    iotSensorList: '物聯網傳感器記錄',
    incidentOrderRecords: '事件報告記錄',
    dataMeterReadings: '數據 / 儀表記錄',
    alertsHistory: '警報歷史',
    visitorManagement: '訪客管理',
    receptionEntranceManagement: '訪客接待 / 登記管理',
    visitorAppointments: '訪客預約記錄',
    visitorVisitRecords: '訪客歷史記錄',
    supportingFunctions: '輔助功能',
    dutyLogbook: '工作日誌',
    documentManagement: '檔案管理',
    documentUpload: '檔案上傳',
    documentAccessRecords: '檔案瀏覽記錄',
    myDocuments: '我的檔案',
    statisticsAnalysis: '統計分析',
    reportCenter: '報表中心',
    inspectionEfficiencyDashboard: '巡檢執行效率儀表板',
    incidentReportDashboard: '事件報告儀表板',
    workOrderResponseTimeDashboard: '工單響應儀表板',
    abnormalityDashboard: '巡查異常儀表板',
    humanEfficiencyDashboard: '人員效率儀表板',
    inventoryMaterial: '庫存材料',
    inventoryManagement: '庫存管理',
    materialUsageRecord: '物料使用記錄',
    switchPropertySuccess: ' 物業實體切換成功！',
    switchLangSuccess: '語言切換成功！',
    rentalManagement: '借還管理',
    rentPropertyList: '物件清單',
    lendReturn: '借用 / 歸還',
    disposalOrder: '報廢處置單',
  },
  login: {
    loginBannerHomeLabel: '物聯網（IoT）傳感模塊',
    loginBannerHomeTitle1: '智慧感知，智控未來，',
    loginBannerHomeTitle2: '設施設備盡在掌控！',
    loginBannerHomeLi1: '實時狀態監控',
    loginBannerHomeLi2: '智能巡檢報警',
    loginBannerHomeLi3: '實時工單調度',

    loginBannerInventoryLabel: '庫存管理模塊',
    loginBannerInventoryTitle1: '微物料，大影響，',
    loginBannerInventoryTitle2: '核心因之而安。',
    loginBannerInventoryLi1: '無縫申請',
    loginBannerInventoryLi2: '靈活分配',
    loginBannerInventoryLi3: '實時庫存控制',

    loginBannerVisitorLabel: '訪客管理模塊',
    loginBannerVisitorTitle1: '零紙化新體驗，安全高效，',
    loginBannerVisitorTitle2: '訪客到訪輕松便捷！',
    loginBannerVisitorLi1: '無紙化登記',
    loginBannerVisitorLi2: '無需安裝APP。',
    loginBannerVisitorLi3: '安全管控，有序進入。',

    loginTitle: '專業巡檢管理系統',
    logIn: '登 入',
    contactUs: '聯絡我們',
    phoneLogin: '手機號碼登入',
    configPhone: '手機號碼',
    accountPass: '密碼',
    accLogin: '帳號登入',
    peshortName: '物業實體代號',
    accountId: '使用者帳號',
    hybrid: '第三方登入',
    email: '第三方帳號',
    vercodeTips: '請輸入圖形驗證碼',
    freeregnow: '免費註冊',
    forgotPass: '忘記密碼？',
    otherLoginMethods: '其他登入方式',

    // 登入页面的联系方式
    labelSg: '新加坡：',
    labelAu: '澳洲：',
    labelHk: '香港：',
    labelTw: '台灣：',
    labelCn: '中國大陸：',

    loginAreaCodeTips: '請選擇區號',
    loginPhoneTips: '請輸入手機號',
    loginPasswordTips: '請輸入密碼',
    loginPeabbrTips: '請輸入物業實體代號',
    loginUserAccountTips: '請輸入使用者帳號',
    loginHybridAccountTips: '請輸入登錄帳號',
    loginHybridImgCodeTips: '請輸入圖形驗證碼',

    webSiteVersion: '您的帳號屬於另一個方案的網站（{anotherSite}），系統已幫您轉換到該網站，請重新登入。',
  },
  register: {
    welcomeReg: '歡迎註冊',
    chinesePhoneTips: '建議使用常用手機號碼',
    configPass: '密碼',
    rePass: '請再次輸入密碼',
    phoneCode: '手機驗證碼',
    regSuccessMsg: '註冊成功，點選【確定】立即登入',
    immReg: '馬上註冊',
    sendCode: '傳送驗證碼',
    inputCodePlh: '請輸入驗證碼',
    inputCodeError: '請輸入驗證碼',
    sendCodeBtn: '傳送驗證碼',
    sendCodeBtning: '{seconds}秒',
    phoneCodeTips: '請點選傳送驗證碼按鈕，我們將傳送簡訊驗證碼到註冊的號碼',
    rePassTips: '請再輸入相同的密碼',
    configNewPassFormat: '請輸入6-20位數字、英文或特殊符號，特殊符號僅支持@#&*_',
    configNewPassFormatTip: '請輸入6-20位數字、英文或特殊符號，特殊符號僅支持@#&*_',
    tips_402001: '此帳號已註冊，請直接登錄',
    existAccountTip: '此帳號已經註冊，是否前往登錄？',
    goToLoginLabel: '前往登入',
    forgetPwdTip: '如若忘記密碼，可以前往忘記密碼頁面找回密碼',
    retrievePwdLabel: '找回密碼',
    goUseFlowTip: '您亦可前往使用流程頁面，進行學習和操作',
    useFlowLabel: '使用流程',
  },
  forgot: {
    loginTitle: '專業巡檢管理系統',
    forgotPass: '忘記密碼？',
    verAcc: '驗證帳號',
    modPass: '修改密碼',
    resComp: '重設完成',
    configPhone: '手機號碼',
    verCode: '驗證碼',
    phoneCode: '手機驗證碼',
    nextStep: '下一步',
    verCodeTips: '請輸入圖形驗證碼',
    phoneCodeTips: '請輸入手機驗證碼',
    phoneIsNotExist: '該號碼不存在',
    correctImageCode: '請輸入正確的圖形驗證碼',
    correctCode: '驗證碼錯誤，請重新輸入或再次傳送',
    sendCodeSuccess: '手機驗證碼已成功傳送到你手機',
    newPass: '新密碼',
    configNewPassformat: '請輸入6-20位數字、英文或特殊符號，特殊符號僅支持@#&*_',
    repNewPassTips: '請再次輸入',
    repNewPass: '再次輸入新密碼',
    passIsConsistent: '兩次輸入的密碼不一致',
    modSuccessMsg: '密碼已重設，請重新登入！',
    modSuccessMsgJumpMsg: '{seconds} 秒後前往 {loginPage}.',
    loginPage: '登入頁面',
    contactPhone: '04-23168028',
    home: '首頁',
    login: '登入',
  },
  forgotAccount: {
    peShortName: '物業實體代號',
    peShortNameTips: '請輸入物業實體代號',
    accountId: '使用者帳號',
    accountIdTips: '請輸入使用者帳號',
    verCode: '驗證碼',
    verCodeTips: '請輸入圖形驗證碼',
    peAdminPhone: '物業實體管理員電話',
    peAdminPhoneTips: '請輸入管理員手機號碼',
    phoneNumError: '手機號碼錯誤',
    correctImageCode: '請輸入正確的圖形驗證碼',
    accmodSuccessMsg: '密碼重設成功，新密碼已傳送到號碼：',
  },
  selectFromTemplate: {
    selMulTemTit: '從模板選擇多選項',
    enterSearch: '請輸入模板名稱',
  },
  saveOptionsTemplate: {
    saveMulTem: '將多選項儲存為模板',
    temName: '模板名稱',
    checkSelectVal: '請新增選項！',
    checkTemNamePlh: '請輸入模板名稱',
    checkTemName: '請輸入模板名稱',
    saveSuccess: '選項模板儲存成功！',
  },
  home: {
    homeTitle: '概覽',
    setting: '設定',
    allProperty: '所有物業',
    homeAccountData: '帳號資料',
    homeAccountDataAll: '所有物業',
    homeAccountDataCurrent: '{property}',
    proentity: '物業實體',
    homeAccount: '使用者帳號',
    homeLoc: '巡檢點',
    instask: '巡檢計劃',
    woTemplate: '工單模板',
    homeRcWoTit: '巡檢單執行情況匯總',
    homeWoTit: '工單執行情況匯總',
    homeIrTit: '事件報告執行情況匯總',

    homeCancel: '取消',
    homeCompleted: '已完成',
    homeExecuting: '進行中',
    homeTimeout: '逾時',
    homeToExecuted: '待執行',
    homeShelve: '暫擱置',
    homeReviewed: '待審核',

    chartTitle: '圖表',
    homeRcTaskTit: '巡檢單執行情況',
    homeWoTaskTit: '工單執行情況',
    homePeIrTit: '事件報告執行情況',
    homeAbLocTit: '異常巡檢點數量',
    homeHrRcTit: '人員巡檢情況',
    noData: '無資料',
    meter: '儀表',
    fold: '收起',
    unfold: '展開',
    switchChartTip: '切換圖表',
    startDate: '開始時間',
    endDate: '結束時間',

    just: '剛剛',
    yearsAgo: '年前',
    monthsAgo: '月前',
    weeksAgo: '週前',
    daysAgo: '天前',
    hoursAgo: '小時前',
    minutesAgo: '分鐘前',

    yearAgo: '年前',
    monthAgo: '月前',
    weekAgo: '週前',
    dayAgo: '天前',
    hourAgo: '小時前',
    minuteAgo: '分鐘前',

    nameAxis: '名稱',
    amountAxis: '數量',
    percentageAxis: '百分比',

    viewTutorial: '查看教程',
    tutorialStep: '教程{num}',
    functionStep: '功能{num}',
    tutorialIsEmpty: '暫無教程',
    tutorialTitle: '學習基礎課程',
    tutorialSubtitle: '僅需 {num} 步讓您輕鬆掌握巡邏巡檢管理秘訣',
    tutorialOtherTitle: '其他功能',
    tutorialOtherSubtitle: '探索更多實用功能',
    tutorialPopTips: '系統檢測到您的帳號尚處於新手摸索期，所以為了確保您能瞭解核心功能，本引導將會每次登陸後自動出現，直至你們成功完成10次巡檢。',
    alwaysAppear: '我為什麼總會出現？',
  },
  homeConfigDashboard: {
    configDashboard: '設定',
    meterConfig: '儀表設定',
    addMeter: '新增儀表',
    meters: '儀表',
    meterSearch: '請輸入監控模板名稱',
    refreshRate: '更新率：{number} 分鐘',
    noRefresh: '數值為0，不更新',
    chartConfig: '圖表設定',
    chartDisplayTime: '圖表顯示時間',
    nearlySevenDays: '最近7天',
    nearlyThirtyDays: '最近30天',
    nearlyNinetyDays: '最近90天',
    thisMonth: '本月',
    lastMonth: '上月',
    thisQuarter: '本季度',
    lastQuarter: '上一季度',
    thisYear: '本年度',
    prevYear: '上一年度',
  },
  homeAccountDetail: {
    peDesc: '物業實體名稱',
    accountId: '使用者帳號',
    accountName: '登錄名稱',
    accountRole: '身份',
    authGroup: '使用者許可權組',
    task: '任務',
    intervalToCreateTempTask: '每 {content} 產生一份巡檢單',
    tempTask: '臨時任務',
    nextInstime: '下次執行巡檢時間',
    notSet: '尚未設定',
    unsPec: '尚未設定',
    status: '狀態',
    suswo: '持續性工單',
  },
  propertyList: {
    proEntity: '物業實體',
    proEntityTips: '「物業實體」就是您管理／安排巡檢的場所，巡邏路線、工單以及統計資訊都要在物業實體中設定與查看。您可以將不同場所的團隊分開設定與管理。',
    searchOfficeName: '搜尋物業實體名稱或物業實體代號',
    current: '當前',
    switchProperty: '切換到此物業實體',
    numberLimit: '已達目前方案可新增的數量上限，如需增加可新增的數量上限請升級方案，有任何疑問請聯絡【Line 官方帳號】客服人員。',
    emptyTips: '未找到相關物業實體。',
    nameAsc: '按名稱升序',
    nameDesc: '按名稱降序',
    createdTimeAsc: '按新增時間升序',
    createdTimeDesc: '按新增時間降序',
    basinfo: '基本資料',
    homeAccount: '使用者帳號',
    homeLoc: '巡檢點',
    comitemman: '常用選項管理',
    instask: '巡檢計劃',
    inslist: '巡檢單',
    increport: '事件報告',
    publishNotice: '發布通知',
  },
  propertyCreate: {
    createPe: '新增物業實體',
    imageUploadShowTip: '建議尺寸為304px*200px，檔案大小最大不超過{size}',
    peabAccountTips: '您登入應填寫的物業實體代號為: ',
    loginMustInput: '系統登入帳號(包含-EN)，也是物業實體識別代號，建議使用英文數字填寫',
    alertPhoneTips: '巡檢人員緊急聯絡電話',
    correctPeName: '請輸入正確的物業實體名稱',
    peNameEmptyTips: '請輸入物業實體代號',
    peNameFormat: '4~20個字元，只能使用數字和字母',
    correctalTerphone: '請輸入正確的電話號碼',
    officialPropertyNameTip: '您的系統登入帳號為：',
    entityName: '物業實體名稱',
    abbreviation: '物業實體代號',
    industry: '物業類別',
    address: '所在地址',
    emergencyContact: '緊急聯絡',
    photoWatermarked: 'APP巡檢點照片開啟浮水印功能',
    uploadPhoto: '上傳圖片',
  },
  propertyDetail: {
    basinfo: '基本資料',
    imageUploadShowTip: '建議尺寸為304px*200px，檔案大小最大不超過{size}',
    peabAccountTips: '您登入應填寫的物業實體代號為：',
    editSuccess: '修改成功',
    officialPropertyNameTip: '您的系統登入帳號為：',
    entityName: '物業實體名稱',
    abbreviation: '物業實體代號',
    industry: '物業類別',
    address: '所在地址',
    emergencyContact: '緊急聯絡',
    photoWatermarked: 'APP巡檢點照片開啟浮水印功能',
    uploadPhoto: '上傳圖片',
  },
  account: {
    mainAccount: '使用者帳號',
    mainAccountTips: '313雲端巡檢系統的所有使用者都需要建立一個使用者帳號才能登入，所有使用者或組別將被授予313雲端巡檢系統 App使用和任務執行許可。您可以依照其身份或工作角色，逐一新增使用者帳號。',
    accountId: '使用者帳號',
    accountName: '登錄名稱',
    accountRole: '身份',
    authGroup: '使用者許可權組',
    dataAdmin: '資料庫管理員',
    pemgr: '物業實體經理',
    generalUser: '一般使用者',
    confirmDelete: '請確認是否刪除該記錄？',
    accountQueryTips: '請輸入使用者帳號或名稱',
    emptyTips: '未找到相關使用者帳號。',
    deleteSuccess: '使用者帳號已成功刪除！',
  },
  accountCreate: {
    newAccount: '新增使用者帳號',
    editAccount: '編輯使用者帳號',
    loginMustInputAcc: '登入時需要填寫，帳號的唯一標識，建議使用英文數字填寫',
    accountIdEmptyTips: '請輸入使用者帳號',
    accountNameEmptyTips: '請輸入名稱',
    accountIdOver: '登入帳號不能超過20字元',
    accountNameOver: '名稱不能超過20字元',
    useAccountPhoneErrmsg: '只能輸入數字',
    checkPasswordeError: '兩次輸入的密碼不一致',
    useAccountPhoneTips: '用於簡訊通知，預設使用註冊手機號碼，可以變更',
    thirdpartyAccount: '用於單一登入的 Azure AD 企業用戶帳號',
    accountPassMsg: '預設密碼為123456',
    accountPassMsg2: '留空不修改密碼',
    dataAdmin: '資料庫管理員',
    pemgr: '物業實體經理',
    dataManTips: "僅'資料庫管理員'具有維護基礎數據的權限。",
    peManTips: "物業實體經理可進行電子標籤資料綁定。",
    accountId: '使用者帳號',
    accountName: '名稱',
    selPhoneNum: '手機號碼',
    email: '第三方帳號',
    accountPass: '密碼',
    checkPassword: '確認密碼',
    checkPasswordPh: '重複相同的密碼',
    createSuccess: '使用者帳號已成功添加！',
    editSuccess: '使用者帳號已成功修改！',
    numberLimit: '已達目前方案可新增的數量上限，如需增加可新增的數量上限請升級方案，有任何疑問請聯絡【Line 官方帳號】客服人員。',
  },
  authManagement: {
    authorizedPersonnel: '授權管理員',
    manageacl: '授權管理',
    authTips: '如果您需要授權其他人管理物業實體，可點選【新增授權人員】，該授權人員需要使用手機號碼登入，權限與物業實體「數據管理員」相同，除了不能編輯本頁的資訊外，可以使用該實體下的其他所有功能。',
    authManPhone: '授權管理員手機號碼',
    authName: '名稱',
    remark: '備註',
    confirmDelete: '請確認是否刪除該記錄？',
    emptyTips: '未找到相關授權管理員。',
    deleteSuccess: '授权管理员已成功刪除！',
  },
  authManagementCreate: {
    addAuthMenber: '新增授權人員',
    editAuthMenber: '編輯授權人員',
    authPhone: '授權手機號碼',
    remark: '備註',
    authPhoneTips: '如果新增的手機號碼尚未註冊，待該手機註冊後，物業實體會與其自動關聯。',
    correctPhoneNum: '請輸入正確的手機號碼',
    phoneEmptyTips: '請輸入手機號碼',
    nameWordsLimit: '名稱不能超过25字',
    createSuccess: '授权人員已成功添加！',
    editSuccess: '授权人員已成功修改！',
  },
  authority: {
    manageacl: '使用者群組權限',
    manageAclDescription: '目前系統權限僅針對於「報表使用及資料展示」做設定',
    aclName: '使用者群組名稱',
    description: '描述',
    deleteAuthGroup: '請確認是否刪除該群組？',
    deleteSelectedBtn: '刪除已選擇 ({count})',
    emptyTips: '未找到相关使用者群組。',
    search: '請輸入使用者群組名稱或描述',
    deleteSuccess: '使用者群組已成功刪除！',
  },
  authorityCreate: {
    addAuth: '新增使用者群組',
    editAuth: '編輯使用者群組',
    aclName: '使用者群組名稱',
    description: '描述',
    aclNameEmptyTips: '請輸入使用者群組名稱',
    accountNameOver: '名稱不能超过20字',
    data: '資料',
    dataItemDescription: '只能查看同群組下的資料',
    setReportButton: '设置報表權限',
    function: '功能',
    createSuccess: '使用者群組已成功添加！',
    editSuccess: '使用者群組已成功修改！',
  },
  authoritySetReport: {
    setReportAuth: '設置報表權限',
    enterSearch: '請輸入搜尋內容',
    rptName: '報表名稱',
    authorized: '權限',
    setSuccess: '設置報表權限成功！',
  },
  authorityMember: {
    groupMenber: '組成員',
    accountId: '使用者帳號',
    accountName: '登錄名稱',
    accountRole: '角色',
    search: '請輸入使用者帳號或名稱',
    deleteSuccess: '組成员已成功刪除！',
    createSuccess: '組成员已成功添加！',
  },
  group: {
    adminGroup: '工作組',
    adminGroupTips: '新增巡檢計劃、工單指定執行人的時候，除了可以選一个使用帳號，也可以選一个工作組作為執行人',
    groupName: '組名稱',
    groupDescription: '組描述',
    memberNum: '成員數量',
    memberNumIs0: '此工作組內沒有成員，請給此工作組添加組成員或者重新選擇工作組',
    enterSearch: '請輸入組名稱或組描述',
    createGroup: '新增工作組',
    editGroup: '編輯工作組',
    emptyTips: '未找到相關工作組。',
    deleteSuccess: '工作組已成功刪除！',
    deleteGroup: '請確認是否刪除該工作組？',
  },
  groupCreate: {
    groupName: '組名稱',
    groupDescription: '組描述',
    groupNameEmptyTips: '請輸入組名稱',
    groupNameOver: '名稱不能超过50字',
    createSuccess: '工作組已成功添加！',
    editSuccess: '工作組已成功修改！',
  },
  groupMember: {
    groupMember: '組成員',
    accountId: '使用者帳號',
    accountName: '登錄名稱',
    accountRole: '身份',
    search: '請輸入使用者帳號或登錄名稱',
    deleteSuccess: '組成员已成功刪除！',
    createSuccess: '組成员已成功添加！',
    selectMemberEmpty: '至少選擇一個帳號',
  },
  groupMemberCreate: {
    addGroupMenber: '增加組成員',
    search: '請輸入使用者帳號或登錄名稱',
  },
  location: {
    mainPatPoint: '巡檢點',
    mainPatPointTips: '設定巡檢點位置／經緯度，以便新增巡檢任務時使用',
    patPoint: '巡檢點名稱',
    patPointName: '巡檢點名稱',
    patPointNum: '巡檢點編號',
    patPointType: '標籤類型',
    needUseLabel: '需要掃描開啟',
    isEffective: '啟用',
    noLocal: '暫無可刪除的巡檢點',
    allLocDeleteTips: '確定要刪除所有巡檢點嗎？',
    importPointer: `成功導入 {count} 行資料`,
    pleaseSelectExcel: '請選擇Excel檔案',
    selectDoc: '選擇檔案',
    selectFileTips: '請選擇檔案',
    pleaseSelectExcelUpload: '請上傳Excel模板檔案：',
    confirmDelete: '請確認是否刪除該記錄？',
    enterSearch: '請輸入巡檢點編號或名稱',
    selectPatPoint: '選擇巡檢點',
    clearAll: '清除全部',
    selectPointTit: '使用中的巡檢點',
    selectedLength: '已選擇 {count}',
    importPatPointExecl: '導入巡檢點',
    importExcelInfo: '請根據導入模板準備資料。如果檔案較大時，導入資料的時間可能較長，請耐心等待。',
    downloadTemplate: '下載模板',
    createPatPoint: '新增巡檢點',
    editPatPoint: '編輯巡檢點',
    emptyTips: '未找到相關巡檢點',
    deleteSuccess: '巡檢點已成功刪除！',
    bluetooth: '藍牙',
    qrcode: 'QR Code',
    nfc: 'NFC 標籤',
    meters: '米',
    detail: '詳細內容',
    initMapFailed: '地圖初始化失敗！',
    initGoogleMapFailed: '載入Google地圖失敗，建議在帳號資料上切換使用百度地圖。',
  },
  locationCreate: {
    patPointNameTips: '巡檢點的名稱，用於定義要檢查的實體位置或資產',
    pointNameEmptyTips: '請輸入巡檢點名稱',
    locLimit100Words: '巡檢點名稱不能超過100字元',
    locNumLimit20Words: '巡檢點編號不能超過20字元',
    patPointNumTips: '巡檢點編號',
    patPointTypeTips: '巡檢點的類型，可以是NFC、QR Code、藍牙或GPS',
    isUseLabel: '巡檢時需先掃描標籤，才能開啟該巡檢點',
    isUseLabelTips: '勾選後，巡檢人員在抵達後須掃描標籤，以確保人在現場',
    isEffectiveTips: '如未啟用，新產生的巡檢單與工單將不包含此巡檢點',
    lng: '經度',
    lat: '緯度',
    enterSearch: '請輸入搜尋內容',
    mapPoint: '巡點位置',
    patPointName: '巡檢點名稱',
    patPointNum: '巡檢點編號',
    patPointType: '標籤類型',
    errorRange: '誤差範圍',
    isEffective: '啟用',
    createSuccess: '巡檢點已成功添加！',
    editSuccess: '巡檢點已成功修改！',
  },
  holiday: {
    holidayManage: '假期管理',
    holidayTips: '設定假期或非工作日的時間範圍，可跨日期設定（如2月4日至2月7日中國農曆春節），為方便工作交接，需設定指定的開始/結束日期。以天/小時為週期的巡檢計劃，在設定的時間範圍內將不自動新增巡檢單，週/月/年檢則為正常巡檢狀態。',
    holidayStart: '假期開始日期',
    holidayEnd: '假期結束日期',
    holidayRemark: '假期備註',
    importPointer: `成功導入 {count} 行資料`,
    pleaseSelectExcel: '請選擇Excel檔案',
    selectDoc: '選擇檔案',
    selectFileTips: '請選擇檔案',
    pleaseSelectExcelUpload: '請上傳Excel模板檔案：',
    copyHoliday: '複製到其他物業實體',
    noperMission: '暫無權限',
    pleaseCheckHoliday: '請選擇要複製的假期',
    deleteSuccess: '假期已成功刪除！',
    enterSearch: '請輸入假期備註',
    importHoliday: '導入假期',
    emptyTips: '未找到相關假期。',
  },
  holidayCreate: {
    createTitle: '新增假期',
    editTitle: '編輯假期',
    holidayStartTips: '在設定的時間範圍內，不新增以天/小時為週期的巡檢單，且系統不對重複的假期設定做限制，請確認設定無誤。',
    holidayStart: '假期開始日期',
    holidayEnd: '假期結束日期',
    noWorkdayRemark: '備註',
    createSuccess: '假期已成功新增！',
    editSuccess: '假期已成功修改！',
    holidayLimitWords: '假期備注不能超過25字元',
    requiredStartdate: '請選擇開始日期',
    requiredEnddate: '請選擇結束日期',
  },
  holidayCopy: {
    pleaseCheckPenum: '請選擇要複製的物業實體',
    copySuccess: '假期已成功複製！',
    propertyEntity: '物業實體',
    enterSearch: '請輸入搜尋內容',
    selected: '已選擇 {count}',
  },
  assets: {
    assetAsccount: '資產台帳',
    assetLedgeRexPlain: '這是新增和設定資產的位置，可以為資產新增照片以便於識別。設定資產後，可以使用它新增工單。',
    enterSearch: '請輸入資產名稱或編號',
    assetsName: '資產名稱',
    assetsId: '資產編號',
    location: '所在位置',
    status: '狀態',
    assetDelTips: `該資產已經在 "{text}" 等 {num} 個工單模板中使用，刪除後將導致產生的工單模板顯示異常，請確認是否刪除？ `,
    confirmDelete: '請確認是否刪除該記錄？',
    deleteSuccess: '資產已成功刪除！',
    assetStatusAll: '全部',
    assetStatusSelect: '請選擇狀態',
    switchAsset: '選擇資產',
    emptyTips: '未找到相關資產。',
  },
  assetsCreate: {
    createTitle: '新增資產台帳',
    editTitle: '編輯資產台帳',
    viewTitle: '資產台帳詳細',
    assetsName: '資產名稱',
    assetBrand: '品牌',
    assetModel: '型號',
    assetManufactureDate: '生產日期',
    assetInstallDate: '安裝日期',
    assetSerialNo: '機身編號',
    assetStatutory: '法定設備',
    assetStatutoryTips: '法定資產是受政府監管和要求約束的資產',
    location: '所在位置',
    assetDesc: '詳細內容',
    pleaseSelectDate: '選擇日期',
    assetPicTypeTips: '只能上傳 .jpg、.jpeg、.png 格式的圖片',
    assetAlert: '資產名稱不能為空',
    assetLocationTips: '所在位置不能為空',
    assetAlertDesLength: '詳細內容不能超過1000字元',
    createSuccess: '資產已成功新增！',
    editSuccess: '資產已成功編輯！',
    status: '狀態',
  },
  assetsLocationSelect: {
    selectLocation: '選擇位置',
    enterSearch: '請輸入巡檢點編號或者名稱',
  },
  sensor: {
    sensor: '物聯網傳感器',
    sensorName: '物聯網傳感器名稱',
    sensorDesc: '描述',
    sensorNum: '物聯網傳感器編號',
    sensorCode: '物聯網傳感器編碼',
    sensorDelTips: `該傳感器已經在 "{text}" 等 {num} 個監控模板中使用，刪除後將導致產生的監控模板顯示異常，請確認是否刪除？ `,
    confirmDelete: '請確認是否刪除該記錄？',
    sensorExplain: '物聯網傳感器設定完成後，可以在此使用傳感器代碼新增傳感器，將傳感器連結到 313雲端巡檢系統。 然後可以將 IoT 數據與此傳感器設定關聯。 可以在這裡設定傳感器內部的儀表，包括單位、讀數類型、下限和上限。',
    enterSearch: '請輸入物聯網傳感器名稱、描述或編碼',
    deleteSuccess: '物聯網傳感器已成功刪除！',
    emptyTips: '未找到相關物聯網傳感器。',
  },
  sensorCreate: {
    createTitle: '新增物聯網傳感器',
    editTitle: '編輯物聯網傳感器',
    viewTitle: '物聯網傳感器內容',
    sensorName: '物聯網傳感器名稱',
    sensorCode: '物聯網傳感器編碼',
    sensorDesc: '描述',
    sensorPic: '照片',
    sensorNameEmptyTips: '物聯網傳感器名稱不能為空',
    sensorCodeEmptyTips: '物聯網傳感器編碼不能為空',
    createSuccess: '物聯網傳感器已成功新增！',
    editSuccess: '物聯網傳感器已成功編輯！',
    meter: '儀表',
    createMeterTitle: '新增儀表',
    editMeterTitle: '編輯儀表',
    meterType: '儀表類型',
    meterUnit: '儀表計量單位',
    meterReading: '儀表讀數類型',
    sensorkey: '物聯網傳感器密鑰',
    sensorLowerLimit: '下限',
    sensorLowerLimitTips: '請輸入下限',
    sensorUpperLimit: '上限',
    sensorUpperLimitTips: '請輸入上限',
    numberTips: '請輸入數字，至多保留兩位小數',
    meterLowerTips: '首頁圖表顯示最小量程',
    meterUpperTips: '首頁圖表顯示最大量程',
    meterSelect: '請選擇',
    meterPleaseSelect: '請選擇儀表',
    meterTypeEmptyTips: '儀表類型不能為空',
    meterUnitEmptyTips: '儀表計量單位不能為空',
    meterReadingEmptyTips: '儀表讀數類型不能為空',
    limitLessThanTips: '下限只能小於或等於上限',
    limitGreatEroreQualHanTips: '上限只能大於或等於下限',
    meterAddTips: '請新增儀表',
  },
  userinfo: {
    accountDetailTitle: '帳號資料',
    change: '修改',
    configPhone: '手機號碼',
    account: '帳號',
    configUserName: '名稱',
    email: '第三方帳號',
    timeZone: '時區',
    onlyKeep: '只保留',
    daystoinsattachment: '天內的巡檢單附件',
    mapSelect: '選擇地圖',
    allowTask: '允許APP跨物業實體接受任務',
    dontShowContactBar: '不顯示底部聯絡資訊',
    dontShowConsultation: '不顯示線上客服',
    phoneMsgTips: '簡訊或語音通知',
    password: '密碼',
    smsCountContent: '簡訊使用量：{num} / {total}（簡訊數量）',
    voiceCountContent: '語音包使用量：{num} / {total}（語音包總數）',
    userLogo: '按此上傳圖片',
    qrLogo: '按此上傳圖片',
    userLogoInfo: '您可上傳「顯示圖片」，建議尺寸為280px*200px，檔案大小最大不超過2MB',
    qrLogoInfo: '您可上傳「QRcode圖片」，建議尺寸為200px*200px，檔案大小最大不超過2MB',
    editSuccess: '修改成功',
  },
  userinfoSsoConfig: {
    microsoftConfiguration: 'OAuth2配置',
    tenantId: '租戶識別碼',
    clientId: '客戶端識別碼',
    secret1: '密鑰',
    secret1Date: '密鑰有效期限',
    description: '描述',
    saveSuccess: '儲存成功',
  },
  userinfoEditPhone: {
    editPhoneTitle: '修改手機號碼',
    configPhone: '手機號碼',
    moreAreaCode: '更多區號',
    oldPassword: '舊密碼',
    newPass: '新密碼',
    confirmNewPassword: '確認新密碼',
    confirmNewPasswordPh: '再次輸入新密碼',
    verCode: '驗證碼',
    phoneCode: '手機驗證碼',
    correctPhoneNum: '請輸入新的手機號碼',
    configNewPassFormat: '請輸入6-20位數字、英文或特殊符號，特殊符號僅支持@#&*_',
    repassTips: '請再輸入相同的密碼',
    correctImageCode: '請輸入正確的圖形驗證碼',
    correctCode: '驗證碼錯誤，請重新輸入或再次傳送',
    getcodeBtn: '傳送驗證碼',
    secgetcodeBtn: '{second} 秒',
    phoneSendSuccess: '驗證碼傳送成功',
    editPhoneSuccess: '手機號碼修改成功！',
  },
  userinfoEditPass: {
    editPassTitle: '修改密碼',
    configNewPass: '新密碼',
    checkPassWord: '確認密碼',
    editPassSuccess: '密碼修改成功！',
  },
  userinfoViewSms: {
    smsInfoDetailTitle: '簡訊傳送資訊',
    enterSearch: '請輸入搜尋內容',
    detailTitle: '詳細',
    smsPeDesc: '所在物業',
    smsContent: '簡訊內容',
    smsSendTime: '傳送時間',
    smsIsVoiceLabel: '語音通知',
    status: '狀態',
    success: '成功',
    failure: '失敗',
    startDate: '開始時間',
    endDate: '結束時間',
    to: '到',
    smsErrorMsgLabel: '錯誤信息',
    smsSendName: '接收者',
    smsSendPhone: '傳送號碼',
    emptyTips: '未找到相關簡訊內容。',
  },
  notice: {
    notice: '通知管理',
    noticeTips: '可向物業實體內的相關使用者發送廣播或通知，通知會傳送到手機APP',
    noticeTitle: '標題',
    noticeOutline: '內容',
    publishName: '發布人員',
    publishTime: '發布時間',
    deleteSuccess: '通知刪除成功！',
    noticeQueryTips: '請輸入通知標題',
    emptyTips: '未找到相關通知。',
  },
  noticeRead: {
    noticeReadTitle: '已讀',
    notifyperName: '被通知人員的名稱',
    isRead: '已讀',
    noticeReadTime: '讀取通知的時間',
  },
  noticePublish: {
    publishNotice: '發布通知',
    noticeTips: '可向物業實體內的相關使用者發送廣播或通知，通知會傳送到手機APP',
    noticeTitlePlaceholder: '請輸入通知標題',
    noticeTitleTips: '請輸入通知標題',
    noticeContentPlaceholder: '請輸入內容',
    noticeContentTips: '請輸入內容',
    noticeTitleOver: '標題不能超過50字元',
    noticeContentOver: '內容不能超過300字元',
    setNoticeRange: '通知指定對象',
    notifyAll: '通知所有人',
    checkSelNoticeper: '請選擇通知人員！',
    publishSuccess: '新的通知發布成功！',
  },
  noticePublishRange: {
    dialogNotifyAllTitle: '選擇通知的人員',
    UserList: '用戶列表',
    clearAll: '清空',
    selected: '已選擇 {count}',
  },
  selectTaskTemplate: {
    enterRsearch: '請輸入搜尋內容',
    instask: '巡檢計劃',
    executor: '執行人',
  },
  task: {
    mainInstask: '巡檢計劃管理',
    mainInstaskTip: '設定巡檢計劃的詳細內容，包括要巡檢哪些巡檢點，每個巡檢點要執行哪些巡查項，以及巡檢計劃的具體執行計劃，由誰負責執行等。',
    name: '計劃名稱',
    inspPlan: '巡檢計劃',
    toCreateTempTask: '每 {interval} {frequnit} 產生一份巡檢單',
    tempTask: '臨時任務',
    onTimeCompletion: '{count} 按時完成',
    overdueCompletion: '{count} 逾時完成',
    cancelled: '{count} 已取消',
    notFollowUp: '{count} 未處理',
    checkStartTask: '【下次預定巡檢時間】或【巡檢執行人】尚未設定，無法啟動巡檢計劃',
    startTask: '巡檢計劃啟動成功！',
    stopTask: '巡檢計劃停止成功！',
    nextInsTime: '下次執行巡檢時間',
    assignee: '執行人',
    insman: '巡檢執行人',
    reviewer: '覆核人',
    insTaskQueryTips: '請輸入巡檢計劃名稱或任务編號',
    deleteSuccess: '巡檢計劃刪除成功！',
    emptyTips: '未找到相關巡檢計劃。',
  },
  taskCreate: {
    createPatTask: '新增巡檢計劃',
    editPatTask: '編輯巡檢計劃',
    taskName: '計劃名稱',
    pleaseInputRctaskName: '計劃名稱不能為空',
    checkSelinsExist: '巡檢執行人不能為空',
    pleaseRcwoDate: '巡檢日期時間不能為空',
    pleaseInterval: '巡檢計劃間隔不能為空',
    nextScheduledTimeTips: '表定下次執行巡檢時間不能為空',
    correctIntervalTips: '請輸入正確的時間間隔',
    patPoinAndPatitem: '巡檢點及巡查項',
    patPlan: '巡檢計劃',
    addTaskNameTips: '指的是在一定時間週期內重複執行的計劃名稱，例如：保全巡邏路線A，設備定期保養，電梯年檢，這些名稱可以被用作巡檢計劃的名稱。',
    setExecutor: '安排巡檢執行人',
    selectExecutor: '選擇人',
    selectGroup: '選擇組',
    perint: '每',
    togenPatList: '產生一份巡檢單',
    intervalTips: '（系統將會在第1，第{nexttime}{frequnit}新增巡檢單）',
    nextInsPlanTime: '表定下次執行巡檢時間為',
    reviseInsPlanTime: '重新修改巡檢時間',
    to: '至',
    groupList: '巡檢組列表',
    executorList: '巡檢執行人列表',
    reviewerList: '巡檢覆核人列表',
    selectReviewerTips: '請選擇巡檢覆核人',
    effTimeRange: '生效時間範圍',
    validPeriod: '此巡檢計劃在',
    validPeriodLast: '之後失效',
    validPeriodTips: '巡檢計劃將在到期日期時間后自動失效',
    weeklySet: '每週設定',
    mon: '星期一',
    tue: '星期二',
    wed: '星期三',
    thu: '星期四',
    fri: '星期五',
    sat: '星期六',
    sun: '星期日',
    other: '其他',
    holiday: '假期',
    monthSeting: '月份設定',
    Jan: '一月',
    Feb: '二月',
    Mar: '三月',
    Apr: '四月',
    May: '五月',
    Jun: '六月',
    Jul: '七月',
    Aug: '八月',
    Sept: '九月',
    Oct: '十月',
    Nov: '十一月',
    Dec: '十二月',
    holidayTips: '如有其他假日或非工作日無需產生巡檢單，可點選左側選單的【假期管理】進行設定。',
    checkSelectExecutor: '請選擇巡檢執行人',
    checkSelectGroup: '請選擇巡檢執行組',
    efftimeRangeInfoOne: '用來設定巡檢的時間範圍，例如：公司的洗手間必須在早上8點到下午5點之間，每小時巡檢1次，則生效時間範圍應設為：08:00 至 17:00，系統會在生效時間範圍內自動或提早新增巡檢單。',
    efftimeRangeInfoTwo: '如果【結束時間】早於【開始時間】，則代表時間範圍為次日的該時間點結束。',
    efftimeRangeInfoThree: '不填寫則代表24小時生效',
    efftimeRangeInfoFour: '這個設定只有在頻率為小時的時候才有效，其他頻率則可留空。',
    inspectionPlanOptions: '巡檢計劃',
    autoCreateTask: '開啟無計劃巡檢模式',
    isNeedAdvance: '需要提前新增巡檢單',
    autoCreateTaskDescRiption: '勾選後，此巡檢計劃不再根據設定的巡檢時間和頻率生成。只要當前巡檢單完成後，立即自動生成下一張巡檢單。',
    isNeedAdvanceInfo: '例如：巡檢計劃的執行時間為上午10:00，如設定巡檢單提前1小時新增，則每天上午9:00巡檢單會自動傳送到巡檢執行人的手機APP裡。',
    needAdvanceHourTocReateins: '需提前 {content} 新增巡檢單',
    openCondition: '巡檢計劃開啟條件',
    openConditionTips: '您可以從「APP」的【設定＞巡檢設定】來設定巡檢計劃的開啟條件，不符合條件的巡檢單將被刪除且停止產生，符合條件的巡檢單則會產生。未設定開啟條件的巡檢單不受影響。',
    pause: '暫停',
    openStart: '啟用',
    multiPersonInspection: '允許多人協作巡檢',
    multiPersonInspectionTips: '如果勾選，則此巡檢單可由組內人員分別執行不同巡檢點，且不可轉發。',
    multiPersonInspectionCheckedTips: '勾選 “允許多人協作巡檢” 後，“巡檢路線必須依照巡點順序巡檢” 將會失效',
    inspectionOptions: '巡檢內容',
    autoCompUponScan: '掃描即完成 / 巡邏棒模式',
    autoCompUponScanInfo: '勾選後，如果該巡檢點沒有檢查項目，該巡檢點將自動完成，以提高巡檢效率。如不勾選，使用者必須點選【完成】按鈕才能繼續巡檢下一個巡檢點。',
    needRoute: '巡檢路線必須依照巡點順序巡檢',
    needRouteInfo: '勾選後，需要按照巡檢點的序號來依序完成巡檢。',
    needPhoto: '必須上傳照片',
    needPhotoInfoOption: '勾選後，此巡檢路線上所有巡點都必須上傳照片才能完成巡檢。',
    scanAgain: '勾選後，此巡檢完需要再次掃描該標籤才能完成巡檢',
    scanAgainInfo: '勾選後，此巡檢點任務完成後必須再次掃描標籤才能前往下一個巡檢點。',
    noCancel: '不允許取消',
    noCancelInfo: '勾選後，此巡檢單不可強制取消。',
    signinAdvance: '需要提前簽名',
    signMethod: '簽名方式',
    handlewrite: '手寫簽名',
    signPic: '簽名拍照',
    managementOptions: '主管相關',
    isNeedReviewer: '需要巡檢覆核人',
    setReviewerInfo: '勾選後，巡檢單在完成時，會自動傳送給指定的物業實體經理進行覆核人。',
    promanagerNotificationOptions: '提醒選項',

    addPatPoint: '新增巡檢點',
    addPatPointTips: '巡檢計劃是一個需定期執行的任務，可透過此按鈕新增巡檢點與巡查項內容。',

    taskRemind: '巡檢單未執行通知',
    taskRemindTips: '勾選後，可設定超過巡檢計劃開始時間多久沒回應時，即發送APP通知給巡檢人員，單位為分鐘。若在指定時間內巡檢人員有簽到記錄，就不再通知；反之則發送通知。',
    needPhotoInfoPart: '勾選後，此巡點必須上傳照片才能完成巡檢。',
    makeWarning: '未按時完成巡檢單通知',
    makeWarningTips: '僅在線巡檢模式有效，超過指定時間未簽到時發出警報。',
    recordOnly: '記錄',
    mustBeMinutes: '應在 {content} 分鐘',
    earlyMinToConsider: '提早 {earlyContent} 分鐘或者延誤 {lateContent} 分鐘為不正常，執行',
    seNum: '序號',
    patContent: '巡查內容',
    feedBackVal: '回報可選項',
    needFeed: '必須回報？',
    colData: '記錄數據？',
    warningCheckNeedRoute: '勾選【巡檢選項】 - 【巡檢路線必須依照巡點順序巡檢】後才生效。',
    taskRemindDesc: '巡檢執行人需在巡檢開始後 {content} 分鐘內開啟巡檢單任務，否則將發送通知。',
    justOneReminder: '只提醒一次',
    remindLimitTip: '最多提醒10次',

    respondIsIntervalLabel: '每隔 {content} 分鐘提醒一次，直至應該完成時間或響應時間到達為止',
    remindRecipientLabel: '設置提醒接收人',
    executor: '執行人',
    peManager: '物業經理',
    remindOther: '其他',

    remindPushMethodLabel: '提醒方式',
    remindPushApp: 'App推送',
    remindPushSms: '簡訊',
    remindPushVoice: '語音通知',
    taskRemindRequired: '巡檢單未執行提醒是必需的',
    reviewerRequired: '巡檢覆核人是必填字段',
    respondIntervalRequired: '時間間隔是必需的',
    leadtimeRequired: '提前生成巡檢單時間是必需的',
    outtimeTimelimitRequired: '完成時間是必需的',
    pointTimeoutTips: '巡檢點到達延誤通知',
    locDelayLabelTip: '需要在巡檢點上設置到達時間，並勾選必須按順序巡檢選項',
    firstPolling: '以第一個巡檢點為準',
    prevPolling: '以前一個巡檢點為準',
    remindIsAlertLabel: '記錄到警報歷史',
    noSupTips: '目前方案不支援該功能！',
    isSaveWithoutLoc: '目前任務沒有新增巡檢點，是否繼續新增？',

    insneedMinuteToComp: '巡檢需在 {content} 分鐘內完成，超過即為逾時完成，將發送逾時提醒',
    overTimeIsAbnormal: '超過 {content} 分鐘時視為不正常',
    taskRemindToFinish: '完成通知',
    taskRemindToFinishTips: '勾選後，一旦巡檢單/工單執行完成後，傳送通知給物業實體經理角色。',
    remindExecuteLabel: '巡檢單執行提醒',
    remindExecuteLabelTip: '巡檢單轉發、覆核、退回將發送提醒給執行人',
    trackingOptions: '追蹤選項',
    calsignTime: '計算簽到時間',
    mileage: '里程數 {content} 公里',
    mileageTips: '里程數不能小於0',
    mileageInfo: '此巡檢路線的總里程數，通常為所有巡點間隔距離的加總。',
    trarecfre: '路徑記錄頻率 {content} 分鐘',
    trarecfreTips: '路徑記錄頻率不能小於0',
    cancelCheckNeedRouteTips: '取消勾選巡檢路線必須依照巡點順序巡檢後，巡檢點到達延誤通知 和 未按時完成巡檢單通知將會失效',
    addPatItem: '新增巡查項',
    selectComItem: '從常用項選擇',
    selectSysItem: '從系統項選擇',
    copyPatItem: '從其它巡檢點中選擇巡查項',
    patPointName: '巡檢點名稱',

    taskExample: '巡檢單產生結果範例',
    taskExampleTipsTitle: '範例產生說明：',
    taskExampleTipsHours: '頻率選擇為小時／天／週：產生2個月範例',
    taskExampleTipsMonth: '月：產生1年範例',
    taskExampleTipsYear: '年：產生10年範例',

    taskUse: '啟用',
    taskGenerate: '產生一份巡檢單',

    remindMethodSmsCheckedTip1: '需勾選 "帳號資料 - 簡訊或語音通知" 才能生效',
    remindMethodSmsCheckedTip2: '簡訊剩余數量為 0',
    remindMethodVoiceCheckedTip: '語音剩余數量為 0',
    locChange: '更換',
    top: '置頂',
    bottom: '置底',
    moveUp: '向上排序',
    moveDown: '向下排序',
    expand: '展開',
    collapse: '收起',
  },
  taskDetail: {
    viewInstaskDetail: '檢視巡檢計劃',
    copyToNewTask: '複製成一個新的巡檢計劃',
    cmpontime: '按時完成',
    cmpovrdue: '逾時完成',
    notfollow: '未處理',
    cancelled: '已取消',
    taskNum: '任务編號',
    taskName: '計劃名稱',
    perint: '每',
    togenPatList: '產生一份巡檢單',
    intervalTips: '（系統將會在第1，第{nexttime}{frequnit}新增巡檢單）',
    nextInsplanTime: '表定下次執行巡檢時間為',
    effTimeRange: '生效時間範圍',
    notset: '尚未設定',
    to: '至',
    nextDay: '次日',
    insman: '巡檢執行人',
    notpoint: '未指派',
    reviewer: '覆核人',
    historyTotal: '歷史統計',
    trarecfre: '路徑記錄頻率',
    modinsExecutor: '修改巡檢執行人',
    selectGroup: '選擇組',
    selectExecutor: '選擇人',
    weeklyEfftime: '每週生效時間',
    monthSeting: '月份設定',
    needAdvance: '需提前',
    toCompover: '內完成，否則視為逾時完成',
    insNeed: '巡檢需在',
    mileage: '里程數',
    kilo: '公里',
    mon: '星期一',
    tue: '星期二',
    wed: '星期三',
    thu: '星期四',
    fri: '星期五',
    sat: '星期六',
    sun: '星期日',
    other: '其他',
    holiday: '假期',
    Jan: '一月',
    Feb: '二月',
    Mar: '三月',
    Apr: '四月',
    May: '五月',
    Jun: '六月',
    Jul: '七月',
    Aug: '八月',
    Sept: '九月',
    Oct: '十月',
    Nov: '十一月',
    Dec: '十二月',
    effect: '生效',
    recentFive: '最近5次巡檢記錄',
    patNum: '巡查單號',
    insplanTime: '表定巡檢時間',
    actComTime: '實際完成時間',
    insmanName: '巡檢人姓名',
    status: '狀態',
    foleventrep: '後續事件報告',
    currencyLoc: '未知巡檢點',
    deleteLocation: '該巡檢點已刪除',
    homeLoc: '巡檢點',
    seNum: '序號',
    patContent: '巡查內容',
    feedBackVal: '回報可選項',
    needFeed: '必須回報？',
    colData: '記錄數據？',
    checkStartTask: '【下次預定巡檢時間】或【巡檢執行人】尚未設定，無法啟動巡檢計劃',
  },
  commonList: {
    commonListTitle: '常用選項管理',
    titleTips: '自訂常用的巡查項目，提高巡檢計劃設定和維護效率。',
    patItemDesc: '巡查項內容',
    category: '分類',
    default: '默認',
    patIntputType: '輸入模式',
    needFeed: '必須回報？',
    coldData: '記錄數據？',
    refCount: '引用次數',
    deleteCommonSuccess: '巡查項已成功刪除！',
    deleteSystemSuccess: '巡查項已成功刪除！',
    commonListButton: '常用項',
    systemListButton: '系統常用項',
    comItemManQueryTips: '請輸入巡查項內容',
    comCategoryQueryTips: '請輸入分類',
    newPatItem: '新增巡查項',
    editPatItem: '編輯巡查項',
    emptyTips: '未找到相關常用選項。',
  },
  commonListCreateCommon: {
    patItemDesc: '巡查項內容',
    categopy: '分類',
    patInputType: '輸入模式',
    feedBackVal: '回報可選項',
    addOne: '新增項目',
    setTem: '從模板選擇',
    tmplEmptyTips: '未找到相關模板。',
    saveTem: '儲存為模板',
    inputLimit: '輸入限制',
    defVal: '預設值',
    defValInfo: '為手機APP中該巡查項的預設回報內容。',
    isNeedFeedBack: '必須回報',
    feedBackInfo: '如果該巡查項設為必須回報，則巡檢人員在手機APP回報巡檢結果時，該巡查項必須填寫記錄。',
    isColData: '儀表數據記錄',
    colDataInfo: '如果是，可在“數據記錄”功能中查詢相關的記錄及圖表。',
    inputInsItemContentTips: '請輸入巡查項內容',
    onehundredandfifty: '巡查項內容不能超過150字元',
    pleaseSelectFeedBack: '請選擇回報',
    checkSelectValLen: '選項請勿超過9個！',
    createSuccess: '新的巡查項已成功新增！',
    editSuccess: '巡查項已成功修改！',
    addPatItem: '新增巡查項',

    isUploadPic: '允許上傳圖片',
    picClassify: '圖片分類',
    defValNeedFromFeedback: '默認值需來自反饋可選項',
    setCom: '設為常用項',
    setComSuccess: '已成功設為常用項！',
  },
  woTemplate: {
    mainWoTemplate: '工單模板管理',
    woTemplateListTips: '設定工單模板的詳細內容，包括要巡檢哪些巡檢點，每個巡檢點要執行哪些巡查項，以及工單的具體執行計劃，由誰負責執行等。',
    pleaseInputWoTemName: '請輸入工單模板名稱或編號',
    woCatSelectTips: '請選擇分類',
    template: '模板',
    name: '模板名稱',
    suswo: '持續性工單',
    onTimeCompletion: '{count} 按時完成',
    overdueCompletion: '{count} 逾時完成',
    cancelled: '{count} 已取消',
    notFollowUp: '{count} 未處理',
    assignee: '執行人',
    insman: '巡檢執行人',
    reviewer: '覆核人',
    categopy: '分類',
    createNewWoTem: '新增新的工單模板',
    createWo: '新增工單',
    deleteSuccess: '工單模板刪除成功！',
    emptyTips: '未找到相關工單模板。',
  },
  woTemplateCreate: {
    tabName: '工單模板',
    woTemplateName: '工單模板名稱',
    woTemplateNameEmptyTips: '工單模板名稱不能為空',
    workOrderTemplateNum: '工單模板編號',
    editWoTemplate: '編輯工單模板',
    woTemplateCat: '工單模板分類',
    woCatDesc: '分類描述',
    woCatSelectTips: '請選擇分類',
    woRule: '工單規則設定',
    deleteLocation: '該巡檢點已刪除',
    deleteAsset: '該資產已刪除',
    woIsEditLoc: '允許修改巡檢點',
    woIsDelLoc: '允許刪除巡檢點',
    wosustainabilily: '持續性工單？',
    wosustainabililyTips: '沒有執行計劃時間限制，可隨時啟動執行。並在任務完成後，系統會自動產生一個同樣的工單。',
    woNeedReviewer: '需要覆核人',
    woReviewerInfo: '如果需要，系統在工單完成後會自動傳送到指定的物業實體經理進行覆核。',
    makeWarning: '未按時完成工單通知',
    needPhoto: '必須上傳照片',
    needPhotoInfo: '勾選後，此巡檢路線上所有巡點都必須上傳照片才能完成巡檢。',
    remindIsAlertLabel: '記錄到警報歷史',
    scanAgain: '勾選後，此巡檢完需要再次掃描該標籤才能完成巡檢',
    scanAgainInfo: '勾選後，此巡檢點任務完成後必須再次掃描標籤才能前往下一個巡檢點。',
    noCancel: '不允許取消',
    noCancelInfo: '勾選後，此巡檢單不可強制取消。',
    taskRemindToFinish: '完成通知',
    taskRemindToFinishTips: '勾選後，一旦巡檢單/工單執行完成後，傳送通知給物業實體經理角色。',
    advanceSetting: '進階設定',
    ifSendWork: '允許給他人發起工單',
    autoCompuponScan: '掃描即完成 / 巡邏棒模式',
    autoCompuponScanInfo: '勾選後，如果該巡檢點沒有檢查項目，該巡檢點將自動完成，以提高巡檢效率。如不勾選，使用者必須點選【完成】按鈕才能繼續巡檢下一個巡檢點。',
    needRoute: '巡檢路線必須依照巡點順序巡檢',
    needRouteInfo: '勾選後，需要按照巡檢點的序號來依序完成巡檢。',
    calSignTime: '計算簽到時間',
    createWoScanLocNecessary: '新增巡檢點時必須掃描',
    createWoScanLocNecessaryDescription: `在APP使用模板新增工單的時候，如點選【替換】功能要更換巡檢點時，一定要先掃描該巡點的QR Code/NFC/藍牙/GPS成功後才能更換。`,
    addAsset: '新增資產',
    currencyLoc: '未知巡檢點',
    assetsName: '資產名稱',
    homeLoc: '巡檢點',
    woTempClassNull: '工單模板分類不能為空',
    createSuccess: '工單模板成功新增！',
    editSuccess: '工單模板成功修改！',
    slaMenuTitle: 'SLA 設定',
    slaDescLabel: '優先級',
    slaFbtLabel: '反饋時限（分鐘）',
    slaTitle: 'SLA（服務等級協議）設定',
    slaSearchPlhld: '請輸入優先級',
  },
  woTemplateDetail: {
    templateName: '模板名稱',
    viewWoTemplate: '檢視工單模板',
    woTemplateNum: '工單模板編號',
    woTemplateName: '工單模板名稱',
    woTemplateCat: '工單模板分類',
    slaFbtLabel: '反饋時限（分鐘）',
    wosustainabilily: '持續性工單？',
    woNeed: '工單需在',
    slaDescLongLabel: 'SLA 設定優先級',
    overBy: '超出',
    woAbnormal: '即為不正常',
    needPhoto: '必須上傳照片',
    currencyLoc: '未知巡檢點',
    deleteAsset: '該資產已刪除',
    deleteLocation: '該巡檢點已刪除',
    meterNum: '儀表編號',
    woFive: '最近5次工單執行狀況',
    woNum: '工單號碼',
    insplanTime: '表定巡檢時間',
    actComTime: '實際完成時間',
    actExecutor: '實際執行人員',
    status: '狀態',
    foleventrep: '後續事件報告',
    woTemplateCopy: '複製成一個新的工單模板',
    insmanName: '巡檢人姓名',
  },
  woList: {
    woList: '工單記錄',
    wo: '工單',
    recNum: '單號',
    desc: '內容',
    details: '詳細內容',
    category: '分類',
    insplanTime: '表定巡檢時間',
    actStartTime: '實際開始時間',
    actComTime: '實際完成時間',
    overTime: '逾時',
    executor: '執行人',
    unspec: '尚未設定',
    homeReviewed: '待審核',
    results: '結果',
    incReport: '{count} 事件報告',
    allInslist: '全部工單',
    nearlyaweekinslist: '最近一週工單',
    nearlyonemonthinslist: '近一個月工單',
    nearlyhreemonthsinslist: '近三個月工單',
    normal: '正常',
    abnormal: '不正常',
    comOnTime: '按時完成',
    waitingStart: '待執行',
    inProgress: '進行中',
    pending: '暫擱置',
    peoCancelled: '人為取消',
    unProcess: '未處理',
    omitLoc: '巡點遺漏',
    notTimeRange: '不在規定時間內',
    createWo: '新增工單',
    createNewWoTem: '新增新的工單模板',
    selectStartDate: '請選擇開始時間',
    selectEndDate: '請選擇結束時間',
    woCatSelectTips: '請選擇分類',
    woSearch: '請輸入工單號碼或工單描述或工單執行人',
    viewMapTrack: '檢視巡檢路徑',
    viewWoTemplate: '檢視工單模板',
    viewDetail: '檢視內容',
    emptyTips: '未找到相關工單。',
  },
  woListDetail: {
    viewWoDetail: '檢視工單',
    print: '列印工單',
    printAbnormal: '列印工單(僅異常項)',
    details: '詳細內容',
    viewPicDetail: '檢視圖片',
    pendingReason: '擱置理由',
    eventPic: '工單照片',
    picFailed: '讀取失敗',
    noPics: '暫無圖片',
    noPicCatData: '暫無數據',
    viewMatdet: '檢視物料',
    materialNum: '物料編號',
    matdetDesc: '描述',
    invtaddqty: '數量',
    materialwh: '倉庫',
    invtshelf: '貨架',
    smsType: '類型',

    matIssue: '發放庫存項目',
    matReturn: '返回庫存項目',
    matAvgcstadj: '平均成本調整',
    matCurbaladj: '當前餘量調整',
    matInsertItem: '插入新庫存項目',
    matReceipt: '接收庫存項目',
    monitorList: '物聯網傳感器記錄',
    sensorCode: '物聯網傳感器編碼',
    sensorName: '物聯網傳感器名稱',
    meterName: '儀表名稱',
    monitorIndicesNum: '測量值',
    monitorIndicesDifference: '差異值',
    monitorIndicesDate: '時間',
    cancelWord: '取消工單',
    reason: '理由',
    enterCancelWord: '請輸入取消工單理由',
    enterCancelWordTips: '請輸入取消工單理由',
    successCancelWord: '該工單已成功取消',
    editWoTips: '如修改在離線執行中的工單，已執行的巡檢資料將會遺失。請確認是否要繼續修改？ 可以讓執行人在APP的工單執行頁面進行修改，以避免巡檢資料遺失。',
  },
  woCreate: {
    createWo: '新增工單',
    editWork: '編輯工單',
    createWoTips: '設定工單任務的詳細內容，包括有哪些巡檢點，每個巡檢點要執行哪些巡查項，由誰負責執行等。',
    woOrderInfomation: '工單信息',
    woName: '工單名稱',
    woCat: '工單分類',
    woNameEmptyTips: '工單名稱不能為空',
    woCatEmptyTips: '工單分類不能為空',
    woCatSelectTips: '請選擇分類',
    woCatDesc: '分類描述',
    nextWoPlanTime: '表定執行時間為',
    woRule: '工單規則設定',
    woSustainability: '持續性工單？',
    woSustainabilityTips: '沒有執行計劃時間限制，可隨時啟動執行。並在任務完成後，系統會自動產生一個同樣的工單。',
    woNeedReviewer: '需要覆核人',
    reviewerEmptyTips: '覆核人不能為空',
    woReviewerInfo: '如果需要，系統在工單完成後會自動傳送到指定的物業實體經理進行覆核。',
    makeWarning: '未按時完成工單通知',
    needPhoto: '必須上傳照片',
    needPhotoInfo: '勾選後，此巡檢路線上所有巡點都必須上傳照片才能完成巡檢。',
    scanAgain: '勾選後，此巡檢完需要再次掃描該標籤才能完成巡檢',
    scanAgainInfo: '勾選後，此巡檢點任務完成後必須再次掃描標籤才能前往下一個巡檢點。',
    noCancel: '不允許取消',
    noCancelInfo: '勾選後，此巡檢單不可強制取消。',
    taskRemindToFinish: '完成通知',
    taskRemindToFinishTips: '勾選後，一旦巡檢單/工單執行完成後，傳送通知給物業實體經理角色。',
    ifSendWork: '允許給他人發起工單',
    autoCompuponScan: '掃描即完成 / 巡邏棒模式',
    autoCompuponScanInfo: '勾選後，如果該巡檢點沒有檢查項目，該巡檢點將自動完成，以提高巡檢效率。如不勾選，使用者必須點選【完成】按鈕才能繼續巡檢下一個巡檢點。',
    needRoute: '巡檢路線必須依照巡點順序巡檢',
    needRouteInfo: '勾選後，需要按照巡檢點的序號來依序完成巡檢。',
    calSignTime: '計算簽到時間',
    selectPic: '工單照片',
    picTips: '根據您所購買的方案，每張工單最多可上傳 {count} 張照片，圖片大小應小於1MB。',
    details: '詳細內容',
    slaFbtLabel: '反饋時限（分鐘）',
    slaDescLongLabel: 'SLA 設定優先級',
    implementationDateRequired: '執行时间是必需的',
    isNeedToChangeLocTips: '尚未指定工單位置，是否需要指定？',
    duse: '啟用',
    createSuccess: '工單成功新增！',
    editSuccess: '工單成功修改！',
  },
  punchInTemplate: {
    mainSignInTemplate: '打卡簽到模板管理',
    pleaseInputSignInTemplate: '請輸入打卡簽到模板名稱',
    signInTemplateCategory: '模板類別',
    tmplName: '模板名稱',
    setSignInTemplateInsexecutor: '執行人',
    punchInMethod: '打卡方式',
    deleteSuccess: '打卡簽到模板成功刪除！',
    emptyTips: '未找到相關打卡簽到模板。',
    valid: '有效',
    invalid: '無效'
  },
  punchInTemplateCreate: {
    createSignInTemplate: '新增打卡簽到模板',
    editSignInTemplate: '編輯打卡簽到模板',
    signInTemplateName: '打卡簽到模板名稱',
    signInTemplateCategory: '模板類別',
    setSignInTemplateInsexecutor: '執行人',
    clockInfrequency: '最小打卡時間間隔',
    clockInfrequencyTips1: '此為設定同一巡檢點兩次打卡需間隔多久時間。 防止誤打卡或誤報工作。',
    clockInfrequencyTips2: '例如：當間隔設定成10秒時， 用戶只能在打卡的10秒後打第二次卡。',
    punchInMethod: '打卡方式',
    signInDesRequired: '請輸入打卡簽到模板名稱',
    assignGroupRequired: '執行人不能為空',
    minimumInterval: '請輸入正確的最小打卡時間間隔',
    pointArrivalTit: '每日巡檢點到場/離場簽到時間管理',
    addPatPoint: '新增巡檢點',
    time: '時間',
    desc: '描述',
    alertWarning: '警報/警告',
    addTimeManagement: '新增簽到時間管理',
    createSuccess: '打卡簽到模板已成功新增！',
    editSuccess: '打卡簽到模板已成功編輯！',
  },
  punchInTemplateAddInstrument: {
    addTimeManagement: '新增簽到時間管理',
    editTimeMamagement: '編輯簽到時間管理',
    time: '時間',
    desc: '描述',
    alertWarning: '警報/警告',
    requiredTime: '請選擇時間',
    requiredDescription: '請選擇描述',
    requiredAlart: '請選擇警告',
    requiredTimeManagement: '請新增簽到時間管理',
  },
  monitorTemplate: {
    mainMonitoringTemplate: '監控模板管理',
    templateName: '監控模板',
    monitorMainTemplateNanceexplain: '這是為了管理每個傳感器的監控規則，更新高水位線或低水位線以觸發特定的工單進行追蹤。',
    monitorQueryTips: '請輸入監控模板名稱或傳感器編碼',
    sensorCode: '物聯網傳感器編碼',
    deleteSuccess: '監控模板成功刪除！',
    disabled: '無效',
    enabled: '有效',
    disable: '關閉',
    enable: '啟動',
    emptyTips: '未找到相關監控模板。',
  },
  monitorTemplateCreate: {
    createMonitoringTemplate: '新增監控模板',
    editmonitor: '編輯監控模板',
    monitoringName: '監控模板名稱',
    meterItem: '傳感器',
    selectMeter: '選擇傳感器',
    sensor: '物聯網傳感器',
    sensorName: '物聯網傳感器名稱',
    sensorCode: '物聯網傳感器編碼',
    sensorDesc: '描述',
    operationUpperLimit: '作為操作上限',
    operationLowerLimit: '作為操作下限',
    operationEqualLimit: '作為操作等於',
    operationIncrement: '作為操作增量',
    addMeterIndices: '新增儀表',
    meter: '儀表',
    alertValue: '警告值',
    workOrderTemplate: '工單模板',
    pleaseInputMonitoringName: '請輸入監控名稱',
    monitoringNameExceedTips: '監控名稱不能超過50個字元',
    createSuccess: '新的監控模板成功新增！',
    editSuccess: '監控模板成功編輯！',
  },
  monitorTemplateAddMeter: {
    meter: '儀表',
    alertValue: '警告值',
    operationUpperLimit: '作為操作上限',
    operationLowerLimit: '作為操作下限',
    operationEqualLimit: '作為操作等於',
    startDate: '開始時間',
    endDate: '結束時間',
    workOrderTemplate: '工單模板',
    isAllowCreateNew: '在上次工單未完成前允許新增新的工單',
    requiredMeter: '請選擇儀表',
    requiredWarnPoint: '請填寫警告值',
    requiredOperation: '必須選擇其中一項操作項',
    requiredCharacteristic: '特徵儀錶只能輸入英文',
    requiredNotCharacteristic: '只能輸入數位',
    selectWorkOrderTemplate: '選擇工單模板',
    addMeter: '新增儀表',
    editMeter: '編輯儀表',
    remindMethod: '自動經以下方式提醒',
    voice: '語音通知',
    sms: '簡訊',
    mobile: 'App推送'
  },
  inspectionRecords: {
    insorder: '巡檢單記錄',
    insorderTips: '檢視具體的巡檢任務執行情況',
    insorderQueryTips: '請輸入巡檢單號或描述或執行人',
    status: '狀態',
    inspOrder: '巡檢單',
    recNum: '單號',
    description: '描述',
    dateTime: '日期',
    insPlanTime: '表定巡檢時間',
    actStartTime: '實際開始時間',
    actComTime: '實際完成時間',
    overTime: '逾時',
    assignee: '執行人',
    insMan: '巡檢執行人',
    unspec: '尚未設定',
    actExecutor: '實際執行人員',
    reviewer: '覆核人',
    homeReviewed: '待審核',
    track: '流程細節',
    inspResults: '巡檢結果',
    viewDetail: '檢視內容',
    viewInstaskDetail: '檢視巡檢計劃',
    viewMapTrack: '檢視巡檢路徑',
    trackEmptyTips: '暫無巡檢路徑',
    rcEndedCompleted: '已結束(已完成)',
    end: '已結束',
    comp: '已完成',
    wfProcess: '待巡檢',
    inProgress: '進行中',
    pending: '暫擱置',
    cancelled: '已取消',
    rcunprocess: '逾時未處理',
    rcAbnormal: '異常',
    rcOmitLoc: '漏巡',
    increport: '事件報告',
    rcWorkOrder: '工單',
    rcNotTimeRange: '非規定時間到達',
    selectStartDate: '請選擇開始時間',
    selectEndDate: '請選擇結束時間',
    allInslist: '全部巡檢單',
    nearlyaweekinslist: '最近一週巡檢單',
    nearlyonemonthinslist: '近一個月巡檢單',
    nearlyhreemonthsinslist: '近三個月巡檢單',
    recordDate: '記錄時間',
    createNewTask: '新增巡檢計劃',
    emptyTips: '未找到相關巡檢單。',
  },
  inspectionRecordsDetail: {
    viewinsListDetail: '檢視巡檢單',
    reportPrint: '列印報告',
    printins: '列印巡檢單',
    printinsExc: '列印巡檢單(僅異常項)',
    insNum: '巡檢單號',
    insDesc: '巡檢內容',
    inscreateTime: '巡檢單產生時間',
    actStartTime: '實際開始時間',
    insplanTime: '表定巡檢時間',
    actcomtime: '實際完成時間',
    overMinToComp: '超 {count} 分鐘完成',
    completeStatus: '完成狀態',
    normal: '正常',
    abNormal: '不正常',
    setAbnormal: '設定為不正常',
    normalChangeBy: '修改人',
    normalChangeDate: '修改時間',
    changeNormalReason: '更改理由',
    insman: '巡檢執行人',
    multipleExecute: '允許多人協作巡檢？',
    unspec: '尚未設定',
    actExecutor: '實際執行人員',
    reviewer: '覆核人',
    homeReviewed: '待審核',
    mileage: '里程數',
    kilo: '公里',
    cancelReason: '取消原因',
    partinsReason: '部分巡檢原因',
    increportnum: '事件編號',
    increportcontent: '事件報告內容',
    reporttime: '報告時間',
    inccomptime: '處理完成時間',
    handleResult: '處理結果',
    status: '狀態',
    detail: '詳細內容',
    withIncreps: '有 {count} 項事件報告',
    insfeedback: '巡檢狀況回報',
    deleteLocation: '該巡檢點已刪除',
    nonpat: '未巡查',
    insAbnormal: '巡查異常',
    locRcWoHitsory: `該異常巡檢點已新增 {count} 次工單`,
    skipReasonTips: '因故跳過',
    situafeedback: '狀況回報',
    arrivalTime: '應簽到時間段',
    skipReason: '跳過原因',
    reviewSkipReason: '覆核人跳過理由',
    gpsReason: '未到達定位點理由',
    registration: '應簽到時間區間',
    earlyMinutes: '提早了',
    lateMinutes: '遲到了',
    reason: '理由',
    reviewReason: '覆核人理由',
    inputReason: '請輸入理由',
    allInspat: '全部巡檢點',
    onlyAbnormal: '僅顯示異常巡檢點',
    onlyPatrolled: '僅顯示已巡檢點（包括異常）',
    onlyUnpat: '僅顯示未巡檢點',
    position: '巡檢點',
    patPointName: '巡檢點名稱',
    patPointNameTips: '巡檢點的名稱，用於定義要檢查的實體位置或資產',
    patPointNum: '巡檢點編號',
    patPointNumTips: '您可為巡檢點進行編號，但不能重複',
    patPointType: '標籤類型',
    patPointTypeTips: '巡檢點的類型，可以是NFC、QR Code、藍牙或GPS',
    errorRange: '誤差範圍',
    errorRangeUnit: '公尺',
    mapPoint: '巡點位置',
    lng: '經度',
    lat: '緯度',
    isUseLabel: '巡檢時需先掃描標籤，才能開啟該巡檢點',
    isUseLabelTips: '勾選後，巡檢人員在抵達後須掃描標籤，以確保人在現場',
    isEffective: '啟用',
    isEffectiveTips: '如未啟用，新產生的巡檢單與工單將不包含此巡檢點',
    viewImgDigLog: '圖片內容',
    viewWOrDigLog: '工單歷史',
    woNum: '工單號',
    woDesc: '工單描述',
    woCreateTime: '工單產生時間',
    sponsor: '發起人',
    signature: '簽名',
  },
  signInList: {
    signInList: '打卡簽到記錄',
    createSignInTemplate: '創建打卡簽到模板',
    enterSearch: '請輸入巡檢點或登入帳號',
    signInTemplateApplyNo: '流水號',
    signInTemplateInputName: '打卡簽到模板名稱',
    signInTemplatePatrolPointName: '巡檢點',
    signInTemplatePunchTime: '預計打卡簽到時間',
    signInTemplatePatrolTime: '實際打卡簽到時間',
    type: '類型',
    alertWarning: '警報/警告',
    taskTemplateLoginName: '使用者帳號',
    signInTemplateLockUser: '實際執行人',
    emptyTips: '未找到相關打卡簽到。',
  },
  sensorReading: {
    monitorList: '物聯網傳感器記錄',
    sensorCode: '物聯網傳感器編碼',
    sensorName: '物聯網傳感器名稱',
    sensorDesc: '描述',
    sensorQueryTips: '請輸入物聯網傳感器名稱、說明或編碼',
    instrumentReadingRecode: '這是透過物聯網傳感器蒐集的歷史記錄，提供傳感器的讀數。',
    emptyTips: '未找到相關物聯網傳感器。',
  },
  sensorReadingDetail: {
    monitorList: '物聯網傳感器記錄',
    securitySensorsTit: '物聯網傳感器',
    sensorCode: '物聯網傳感器編碼',
    sensorName: '物聯網傳感器名稱',
    monitorNum: '監控模板編號',
    monitorName: '監控模板名稱',
    sensorDesc: '描述',
    meterPic: '照片',
    sensorPicError: '上傳失敗',
    monitorIndices: '儀表',
    monitorIndicesNum: '測量值',
    monitorIndicesUnit: '單位',
    monitorIndicesDifference: '差異值',
    monitorIndicesDate: '時間',
    meter: '儀表',
  },
  event: {
    eventreplist: '事件報告記錄',
    eventreplistTips: '檢視和追蹤巡檢過程中發現的事件報告',
    incNum: '編號',
    content: '內容',
    position: '巡檢點',
    reporter: '報告人',
    reportTime: '報告時間',
    priority: '重要性',
    results: '結果',
    handler: '處理人',
    handlerResult: '處理结果',
    eventreplistQueryTips: '請輸入事件報告單號或描述或巡檢點或處理人',
    showWep: '僅顯示待處理報告',
    setEmailNotice: '設定電子信箱(Email)通知',
    setEmailNoticeTips: '設定電子信箱(Email)通知後，事件報告產生時，系統會傳送Email給下列人員。可設定多個電子信箱，中間請使用英文逗號（,）分隔，',
    setEmailNoticeSuchTips: '如：abc@313fm.com, lucy@313fm.com, tom@313fm.com',
    emailAddress: '電子信箱(Email)地址',
    eamilAddressError: '電子信箱(Email)格式錯誤',
    allIncreport: '全部事件報告',
    nearlyaweekinc: '最近一週事件報告',
    nearlyOneMonthinc: '近一個月事件報告',
    nearlyThreeMonthsInc: '近三個月事件報告',
    setEmailSuccess: '邮箱儲存成功！',
    viewDetail: '檢視內容',
    reportResults: '快速匯報結果',
    comp: '已完成',
    can: '已取消',
    wfp: '待處理',
    emptyTips: '未找到相關事件報告。',
  },
  eventDetail: {
    viewIncReportDetail: '檢視事件報告',
    increportNum: '事件編號',
    increportDesc: '事件內容',
    increportPos: '發生位置',
    reporter: '報告人',
    reportTime: '報告時間',
    cancelTime: '取消時間',
    incComptime: '處理完成時間',
    handleResult: '處理结果',
    dealerout: '處理人/外包商',
    increportesultman: '匯報結果操作人',
    increportTime: '匯報結果操作時間',
    increportImg: '事件照片',
    increportVideo: '事件影片',
    increportAudio: '事件錄音',
    increportResult: '匯報結果',
  },
  eventCollect: {
    increportDesc: '事件內容',
    incComptime: '處理完成時間',
    handleResult: '處理結果',
    dealerout: '處理人/外包商',
    handleComp: '處理完成',
    notNeddCancel: '無需處理／取消',
  },
  dataList: {
    datacolrecord: '數據／儀表記錄',
    datacolrecordTips: '檢視巡檢點的各項數據歷史記錄',
    datacolrecordQueryTips: '請輸入巡檢點位置或數據記錄名稱',
    patPointLoc: '巡檢點位置',
    dataColitem: '數據記錄項目',
    deleteLocation: '該巡檢點已刪除',
    emptyTips: '未找到相關數據／儀表記錄。',
    view: '查看數據紀錄',
  },
  dataListDetail: {
    title: '數據記錄明細',
    patPointLoc: '巡檢點位置',
    datacolitem: '數據記錄項目',
    datacolTime: '記錄時間',
    datacolVal: '記錄數值',
    inslist: '巡檢單',
    instask: '巡檢計劃',
    emptyTips: '未找到相關數據。',
  },
  alertHistory: {
    rcAlert: '警報歷史',
    alert: '警報',
    recnum: '單號',
    desc: '內容',
    executor: '執行人',
    alertLoc: '警報地點',
    alertMsg: '警報內容',
    alertTime: '警報時間',
    results: '結果',
    handler: '處理人',
    handleContent: '處理內容',
    handleDate: '處理時間',
    rcAlertSearchEnterTips: '請輸入單號或標題或警報內容或處理人',
    selectStartDate: '請選擇開始時間',
    selectEndDate: '請選擇結束時間',
    viewDetail: '檢視內容',
    viewinsListDetail: '檢視巡檢單',
    viewWoDetail: '檢視工單',
    handleAlertInfo: '處理警報內容',
    processed: '已處理',
    unprocessed: '未處理',
    emptyTips: '未找到相關警報。',
  },
  alertHistoryHandleAlerts: {
    handleAlertInfo: '處理警報內容',
    insDesc: '巡檢內容',
    alertLoc: '警報地點',
    alertMsg: '警報內容',
    handleDate: '處理時間',
    handleContent: '處理內容',
    handler: '處理人',
    incresultover: '內容不能超過500字元',
    placeholderHandler: '處理人不能為空',
  },
  alertHistoryDetail: {
    viewRcAlert: '檢視警報內容',
    alertNum: '警報編號',
    insDesc: '巡檢內容',
    insMan: '巡檢執行人',
    alertLoc: '警報地點',
    alertTime: '警報時間',
    alertMsg: '警報內容',
    handler: '處理人',
    handleDate: '處理時間',
    handleContent: '處理內容',
  },
  guestQrcode: {
    guestQrcode: '訪客接待／登記管理',
    guestManageDescription: '此物業實體的訪客登記位置。例如：XX 大樓1樓服務台',
    enterSearch: '請輸入名稱',
    addGuestQrcode: '新增訪客登記位置',
    locTips: '請選擇巡檢點',
    configUserName: '名稱',
    shareByEmail: '透過電子信箱(Email)分享',
    shareByEms: '透過簡訊分享',
    emailAddress: '電子信箱(Email)地址',
    emailSetErrMsg: '請輸入正確的電子信箱(Email)地址',
    inputAndEnter: '請輸入內容並按Enter，再按送出。',
    sendSuccess: '傳送成功',
    secondresend: `{seconde} 秒後重新傳送`,
    correctAlertPhone: '請輸入正確的手機號碼',
    selPhoneNum: '手機號碼',
    detail: '詳細內容',
    guestBookIngUrl: '預約登記網頁',
    entranceQrcodeTips: '請列印此QR Cdoe並放置於入口處，方便客戶拜訪時掃描並填寫資料。',
    homeLoc: '巡檢點',
    visitorQuestionTmpl: '訪客問題模板',
    emptyTips: '未找到相關訪客登記位置。',
    shareTips: '訪客預約信息有更改，請儲存後再分享',
  },
  guestReserve: {
    guestReserve: '訪客預約記錄',
    guestReserveDescription: '這裡顯示的是所有訪客的預約記錄，包括過去和未來的預約。任何訪客透過網頁登記的預約記錄都會顯示在這裡。',
    pleaseSelectStatus: '請選擇狀態',
    guestReserveName: '訪客姓名',
    guestReserveTime: '預約時間',
    guestCompany: '訪客公司',
    contactPerson: '聯絡人',
    guestCount: '來訪次數',
    guestStatus: '狀態',
    startDate: '開始時間',
    endDate: '結束時間',
    guestCompanyNullMsg: '請輸入訪客公司名稱',
    confimCancelGuest: '確定取消預約？',
    cancelSuccess: '成功取消預約！',
    guestReserveDetail: '訪客資訊',
    guestLocalAddress: '登記位置',
    guestName: '訪客姓名',
    guestBookingTime: '預約時間',
    guestPhone: '聯絡方式',
    guestIdCard: '證件號碼末4碼',
    guestMember: '同行訪客',
    depVisitedComplany: '受訪公司或部門',
    guestPurpose: '來訪目的',
    guestContact: '受訪者姓名',
    guestContactPhone: '受訪者電話',
    guestQrcodeValidTime: 'QR Code有效期',
    guestQrcodeValidTimepl: '只有在拜訪期間內，拜訪受訪公司時出示的QR Code才有效。',
    visitQrcode: '預約條碼',
    emptyTips: '未找到相關訪客預約。',
  },
  guestHistory: {
    visitorQuestionnair: '訪客問題模板',
    visitHistory: '訪客歷史記錄',
    visitHistoryDescription: '登記預約的訪客來訪後，出示QR Code的歷史記錄',
    guestCompanyNullMsg: '請輸入訪客公司名稱',
    guestReserveName: '訪客姓名',
    guestReserveTime: '預約時間',
    visitTime: '訪問時間',
    guestCompany: '訪客公司',
    contactPerson: '聯絡人',
    isEntryAllow: '允許進入？',
    isAllow: '允許',
    isNotAllow: '不允許',
    setting: '設定',
    keepRecordsByDay1: '只保留',
    keepRecordsByDay2: ' 天的訪問記錄',
    daysNoNull: '天數不能為空',
    pleaseInputMinMaxNumber: `請輸入 {min} 至 {max} 之間的整數`,
    editSuccess: '修改成功',
    emptyTips: '未找到相關訪客歷史。',
  },
  guestRegister: {
    guestBooking: '訪客預約',
    guestRegister: '訪客登記',
    guestBookingTimeStartNullMsg: '請選擇日期',
    guestBookingTimeEndNullMsg: '請選擇時間',
    guestNameNullMsg: '請輸入訪客姓名',
    guestFormLengTherrmsg: `長度在 {min} 到 {max} 個字元之間`,
    guestCompanyNullMsg: '請輸入訪客公司名稱',
    guestAddressNullMsg: '請輸入訪客地址',
    guestDepVisitedCompanyMsg: '請輸入受訪公司或部門',
    guestPurposeNullMsg: '請輸入來訪目的',
    guestContactNullMsg: '請輸入聯絡人姓名',
    guestContactPhoneNullMsg: '請輸入聯絡人電話',
    guestQrcodeValidTimeNullMsg: '請選擇QR Code有效期限',
    qrCodeTimeoutMsg: 'QR Code有效期限的結束時間不能提前於預約時間',
    guestBookingTime: '預約時間',
    pleaseSelectDate: '選擇日期',
    pleaseSelectTime: '選擇時間',
    guestName: '訪客姓名',
    guestPhone: '聯絡方式',
    guestIdCard: '證件號碼末4碼',
    guestMember: '同行訪客',
    guestMemberpl: '如同行訪客姓名及聯絡方式',
    guestCompany: '訪客公司',
    depVisitedCompany: '受訪公司或部門',
    guestPurpose: '來訪目的',
    guestContact: '受訪者姓名',
    guestContactPhone: '受訪者電話',
    guestQrCodeValidTime: 'QR Code有效期',
    to: '至',
    startDate: '開始時間',
    endDate: '結束時間',
    guestPhotosTips: '只能上傳.jpg、.jpeg、.png格式的照片，且不超過8MB',
    guestQrcodeValidTimepl: '只有在拜訪期間內，拜訪受訪公司時出示的QR Code才有效。',
    checkGuestQrcode: '檢視預約QR Code',
    repeatSubimtFailMsg: '不能提交相同的資料，如需重新申請，請至少修改一項資料',
    visitregisterSuccess: '登記成功',
    guestQrCode: '訪客接待／登記管理',
    bookingSuccess: '預約成功',
    bookingSuccessDescription: '請將該QR Code圖片提供給訪客並妥善保管，來訪當天需出示給入口管理員',
  },
  workTableLog: {
    gwrzlb: '工作日誌清單',
    gwrzDesc: '有些物業管理人員，需要將每天做的工作（巡檢/工單）、發生的事情都記錄下來，叫做工作日誌。',
    searchLog: '請輸入狀況發生位置或狀況內容或執行人',
    selectStartDate: '請選擇開始時間',
    selectEndDate: '請選擇結束時間',
    logStartDate: '事件開始時間',
    logEndDate: '事件結束時間',
    logSource: '來源',
    logContentDetail: '內容細節',
    logLoc: '位置',
    logExeName: '執行人名稱',
    logInputTime: '輸入時間',
    createLog: '新增日誌',
    workOrder: '工單',
    inspectionForm: '巡檢單',
    jobLog: '工作日誌',
    eventReport: '事件報告',
    emptyTips: '未找到相關工作日誌。',
  },
  workTableLogCreate: {
    addLog: '新增日誌',
    editLog: '編輯日誌',
    logDetail: '日誌記錄',
    logStartDate: '事件開始時間',
    logEndDate: '事件結束時間',
    logLoc: '位置',
    logContentDetail: '內容細節',
    logPic: '照片',
    logPics: '照片',
    logHr: '執行人帳號',
    logHrName: '執行人名稱',
    logInputTime: '記錄時間',
    logType: '事件種類',
    logSource: '來源',
    logOddNumbers: '單號',
    logPatPonit: '巡檢點',
    pleaseSelectOrInput: '請選擇或輸入事件種類',
    assetsName: '資產名稱',
    homeLoc: '巡檢點',
    logPlace: '巡檢點',
    logStartTime: '巡查時間',
    nullTips: '加註*號部份請務必輸入',
    createSuccess: '新的工作日誌已成功新增！',
    editSuccess: '工作日誌已成功編輯！',
  },
  fileUpload: {
    documentationLibrarylist: '檔案列表',
    documentationDetails: '此功能旨在讓物業實體經理向同一物業下的使用者發送檔案，使用者可以透過手機或電腦瀏覽檔案。 管理員可以編輯檔案說明、類別、目標使用者群組、推播通知和使用者確認。',
    fileName: '檔案名稱',
    fileDescription: '檔案描述',
    fileType: '類別',
    fileAttributeEntity: '物業實體',
    fileTargetUserGroup: '目標使用者群組',
    filePostToEveryone: '傳送到所有人',
    filePushNotification: '推播通知',
    fileUserConfirmationIsRequired: '需要使用者確認',
    fileReleaseTime: '發送時間',
    allUsers: '所有使用者',
    fileUploadEnterRsearch: '請輸入檔案名稱或描述搜尋',
    deleteSuccess: '檔案刪除成功！',
    withdrewSuccess: '檔案撤回成功！',
    releaseSuccess: '檔案發佈成功！',
    confirmWithdrawFile: '請確認是否撤回',
    confirmReleaseFile: '請確認是否發送',
    withdraw: '撤回',
    release: '發送',
    emptyTips: '未找到相關檔案。',
  },
  fileUploadPreview: {
    uploareadTip: '我已閱讀並瞭解內容',
    uploadAutographName: '使用者名稱',
    namePlh: '請填寫閱讀人的名稱',
    actexecutorRequried: '使用者名稱是必填項',
  },
  fileUploadCreate: {
    createDocumentationLibrary: '新增檔案',
    editDocumentationLibrary: '編輯檔案',
    fileName: '檔案名稱',
    uploadFilesSync: '上傳檔案即可同步名稱',
    fileDescription: '檔案描述',
    fileType: '類別',
    pleaseSelectCategory: '請選擇類別',
    fileAttributeEntity: '物業實體',
    fileTargetUserGroup: '目標使用者群組',
    fileUserConfirmationIsRequired: '需要使用者確認',
    filePushNotification: '推播通知',
    allUsers: '所有使用者',
    selectWorkGroup: '選擇工作組',
    everyPushTime: '每{day}天，最多推送{resendnum}次。',
    doNotPush: '不推送',
    pushOnce: '推送一次',
    everyDay: '每天',
    pleaseUploadFile: '請上傳檔案',
    pleaseFileContent: '請輸入檔案描述',
    pleaseSelectResendNum: `通知重發次數不能超過{resendnum}`,
    pleaseSelectResendNumNull: `通知重發次數不能為0`,
    createSuccess: '檔案成功新增！',
    editSuccess: '檔案已成功編輯！',
  },
  fileUploadTable: {
    fileUploadList: '檔案瀏覽記錄',
    fileTableEnterRsearch: '請輸入檔案名稱或描述搜尋',
    fileName: '檔案名稱',
    fileDescription: '檔案描述',
    fileAttributeEntity: '物業實體',
    fileUploadUser: '上傳者名稱',
    fileUploadOpenDate: '首次讀取時間',
    fileUploadConfirmDate: '已閲確認時間',
    fileUploadOpenNum: '讀取次數',
    emptyTips: '未找到相關檔案。',
  },
  fileUploadTableDetail: {
    uploadHistoryList: '歷史記錄',
    uploadExecutor: '使用者名稱',
    uploadViewTime: '訪問時間',
    accessRecords: '訪問記錄',
  },
  fileUploadMyHistory: {
    title: '我的檔案',
    all: '全部',
    read: '已讀',
    unread: '未讀',
    fileDescription: '檔案描述',
    fileType: '類別',
    fileUserConfirmation: '使用者已確認',
    fileReleaseTime: '發送時間',
    fileUploadRead: '已讀取？',
  },
  selectPunchIn: {
    selectPunchInTemplate: '選擇打卡模板',
    punchInTemplateSearch: '請輸入打卡模板名稱',
    punchInTemplateInputName: '打卡模板名稱',
    punchInTemplateNum: '打卡模板編號',
  },
  selectRcWo: {
    inslist: '巡檢單',
    patNum: '巡檢單號',
    insplanTime: '表定巡檢時間',
    executor: '執行人',
    enterSearch: '請輸入搜尋內容',
  },
  rptCenter: {
    rptCenter: '報表中心',
    rptCenterTips: '未來將逐步新增各種統計報表，如現有報表格式不符合您的需求，可透過 313fm【Line 官方帳號 】反應相關需求，我們的產品經理將會在第一時間分析其可行性，或許它很快就會出現在這裡，提高您以及其他與您有相同需求的用戶工作效率。',
    rptNameTips: '請輸入報表名稱',
    rptName: '報表名稱',
    rptView: '效果預覽',
    runCount: '執行次數',
    classification: '分類',
    classificationPlh: '請選擇分類',
    nameSort: '按名稱排序',
    frequencySort: '按次數排序',
    run: '執行',
    runRpt: '執行報表',
    autoEmailSetting1: '自動傳送',
    autoEmailSetting2: '模板設定',

    runFieldRequired: '{field} 是必需的',
    yearMonthDay: '年月日',
    yearMonth: '年月',
    rptYear: '年',
    startDate: '開始時間',
    endDate: '結束時間',
    startEndDateTime: '開始和結束時間',
    startEndMonth: '開始和結束月份',
    insman: '巡檢執行人',
    executor: '執行人',
    patPointLoc: '巡檢點位置',
    woTemplate: '工單模板',
    punchInTemplate: '打卡模板',
    locNoPatrol: '巡檢點未巡',
    newCreate: '最新新增',
    patPointName: '巡檢點名稱',
    patPointNum: '巡檢點編號',
    checkOutPhoti: '輸出照片',
    fileName: '檔案名稱',
    contentDescription: '內容說明',
    noout: '無需輸出巡查項',
    fileUploadUser: '上傳者名稱',
    logSource: '來源',
    accountGroup: '工作組',
    iffy: '按巡檢點分頁',
    instask: '巡檢計劃',
    homeLoc: '巡檢點',
    rcWoStatus: '巡檢單狀態',
    showGpsCheckbox: '顯示GPS坐標',
    showDescCheckbox: '時間降序',
    taskStartDate: '計劃開始時間',
    taskEndDate: '計劃結束時間',
    taskStartEndDateTime: '計劃開始和結束時間',
    stentValDate: '請選擇計劃開始和結束時間或者實際開始和完成時間',
    taskFinishStartDate: '實際開始時間',
    taskFinishEndDate: '實際完成時間',
    actualStartCompletionDateTime: '實際開始和完成時間',
    rptparamsfb: '只顯示有回報記錄',
    abnormalPonitOnly: '僅顯示巡檢異常',
    rptExestatus: '執行狀態',
    rptExestatusNoComp: '只輸出未完成',
    rptExestatusComp: '只輸出已完成',
    missingPointPrompt: '巡檢點遺漏提醒',
    onlyerrcwo: '僅顯示含事件報告的巡檢單',
    inslist: '巡檢單',
    result: '狀況回報',
    isShowPic: '顯示巡檢照片',
    isShowEventReport: '顯示事件報告',
    isShowDetail: '顯示詳細內容',

    // 報表中心自定义参数
    rpcustomparam: '自訂參數',
    rpwonum: '工單編號',
    rpwodesc: '工單內容',

    emptyTips: '未找到相關報表。',
  },
  rptCenterTemplateCreate: {
    description: '描述',
    tempLang: '語言',
    tempParams: '參數',
    createTempTips: '請輸入標題',
    autoMailSet: '自動傳送電子信箱(Email)設定',
    emailSubject: '信件標題',
    sendCycle: '寄送週期',
    cycled: '每天',
    cyclew: '每週（週一）',
    cyclem: '每月（1號）',
    schedule: '預計發送時間',
    sendTime: '數據時間段',
    sendTimeDayBefore: '前一天的數據',
    sendTimeLastWeek: '前一週的數據',
    sendTimeLastMonth: '上個月的數據',
    sendTimeCustomTime: '自定義時間段',
    dayBefore: '前一天',
    today: '今天',
    weekAgoDay: '一週前當天',
    setEmail: '電子信箱(Email)地址',
    emailSetErrMsg: '請輸入正確的電子信箱(Email)地址',
    emailSetErrMsg2: '至少輸入一個電子信箱(Email)地址',
    lessThan24HoursTips: '時間差必須小於24小時',
    endTimeLessThanStartTimeTips: '結束時間必須晚於開始時間',
  },
  rptCenterTemplate: {
    searchByDescription: '按描述搜尋',
    description: '描述',
    creator: '新增人名稱',
    create: '新增',
  },
  rcwosum: {
    insexeeffTitle: '巡檢執行效率儀表板',
    nearlySevenDays: '最近7天',
    nearlyThirtyDays: '最近30天',
    nearlyNinetyDays: '最近90天',
    thisMonth: '本月',
    lastMonth: '上月',
    thisQuarter: '本季度',
    lastQuarter: '上一季度',
    thisYear: '本年度',
    prevYear: '上一年度',
    statusTitle: '巡檢單狀態',
    xAxis: '計劃執行日期',
    yAxis: '數據單數量',
  },
  eventsum: {
    eventRepstatiTitle: '事件報告儀表板',
    nearlySevenDays: '最近7天',
    nearlyThirtyDays: '最近30天',
    nearlyNinetyDays: '最近90天',
    thisMonth: '本月',
    lastMonth: '上月',
    thisQuarter: '本季度',
    lastQuarter: '上一季度',
    thisYear: '本年度',
    prevYear: '上一年度',
    xAxis: '报告时间',
    yAxis: '数量',
  },
  responseTimeDashboard: {
    responsetimeTitle: '工單響應儀表板',
    responSetimeItemTitle: '響應時間',
    wocatSelectTips: '請選擇分類',
  },
  locsum: {
    patabnstaTitle: '巡查異常儀表板',
    topTen: '巡檢點出現異常《前10名》',
    xAxis: '巡檢時間',
    yAxis: '異常巡檢點數量',
  },
  hrsum: {
    staffeffTitle: '人員效率儀表板',
  },
  releaseHistory: {
    releaseHistory: '版本歷史記錄',
    updateStatisics: '更新統計',
    lastUpdateDate: '最新更新日期',
    updateNum: '近一年更新次數',
    updateRecords: '更新記錄',
    updateDate: '更新日期',
    updateLog: '更新日誌',
    showInfo: '顯示資訊',
    lastMonth: '近一個月',
    lastThreeMonth: '近三個月',
    halfaYear: '近半年 ',
    lastYear: '近一年',
  },
  // 安全规则
  securityRules: {
    securityRulesTitle: '安全衛士',
    securityRulesTitleTips: '安全衛士能即時監控人員工作狀態，若在固定時間內（如10分鐘）無移動，系統將呼叫機主一段時間（如2分鐘）。機主若清醒，輕輕搖動電話即可清除呼叫；若無反應，系統將向後台警報。此功能大幅提升現場工作人員（包括清潔、保安、工程師等獨自工作人員）的職業安全保障。',
    enterSearch: '請輸入安全衛士名稱',
    createSecurityRules: '新增安全衛士',
    editSecurityRules: '編輯安全衛士',
    securityRulesName: '安全衛士名稱',
    groupNumberOfHr: '成員數量',
    securityMonitoringInterval: '長時間不移動時間閾值（分鐘）',
    securityMonitoringDelay: '客戶響應時間閾值（分鐘）',
    securityLastName: '最後修改人',
    securityLastTime: '最後修改時間',
    confirmDelete: '請確認是否刪除該記錄？',
    deleteSuccess: '安全衛士已成功刪除！',
    emptyTips: '未找到相關安全衛士。',
  },
  securityRulesCreate: {
    securityRulesName: '安全衛士名稱',
    securityRulesNameTips: '請輸入安全衛士名稱',
    securityRulesModel: '激活方式',
    securityRulesModelLogin: '登錄時',
    securityRulesModelLoginTips: '當用戶登錄之後，系統開始監測, 直到登出',
    securityRulesModelRcwo: '巡檢時',
    securityRulesModelRcwoTips: '當用戶掃描任何巡檢單或者工單的第一個巡檢點時，系統開始監測，直到所有巡檢單和工單完成',
    securityInterval: '當人員連續 {interval1} 分鐘無移動，并且在 {interval2} 分鐘后機主仍無反應，',
    securityAutoRemindMethod: '自動經以下方式提醒：',

    securityInspmntr: '安全事件（閃爍並播放聲音）',
    voiceMsgTips: '語音通知',
    smsMsgTips: '簡訊',
    protectionList: '被提醒人',
    protectionListTips: '請選擇被提醒人',
    phoneIsEmptyTips: '電話號碼為空，請先設置電話號碼',
    selectExecutor: '選擇人',
    phone: '電話號碼',
    createSuccess: '安全衛士已成功新增！',
    editSuccess: '安全衛士已成功修改！',
    securityMonitoringIntervalRangeTips: '長時間不移動時間閾值為：5 ~ 60 分鐘',
    securityMonitoringIntervalEmptyTips: '請輸入長時間不移動時間閾值',
    securityMonitoringDelayRangeTips: '客戶響應時間閾值需小于長時間不移動時間閾值',
    securityMonitoringDelayEmptyTips: '请输入客戶響應時間閾值',
  },
  securityRulesMember: {
    groupMember: '組成員',
    search: '請輸入使用者帳號或名稱',
    addGroupMenber: '新增',
    deleteSuccess: '成員已成功刪除！',
    createSuccess: '成員已成功新增！',
  },
  // 常用理由管理
  commonReason: {
    commonReasonTitle: '常用理由管理',
    commonReasonSearchTip: '請輸入理由描述',
    commonReasonDesc: '理由描述',
    commonReasonType: '分類',
    commonReasonAdd: '新增理由項',
    commonReasonEdit: '編輯理由項',
    commonReasonTypeSelectPlh: '請選擇分類',
    commonReasonTypeSelectTip: '請選擇分類',
    commonReasonSystemTitle: '系統默認理由',
    deleteSuccess: '常用理由刪除成功！',
    commonReasonInputDescTip: '請輸入理由描述',
    commonReasonDescOver100: '不能超過100字元',
    createSuccess: '常用理由已成功新增！',
    editSuccess: '常用理由已成功修改！',
    emptyTips: '未找到相關常用理由。',
    selectTips: '請選擇理由',
  },
  // SLA
  sla: {
    slaTitle: 'SLA（服務等級協議）設定',
    slaSearchPlhld: '請輸入優先級',
    slaDescLabel: '優先級',
    slaFbtLabel: '反饋時限（分鐘）',
    slaDescLongLabel: 'SLA 設定優先級',
    slaAddTitle: '新增 SLA 設定',
    slaEditTitle: '編輯 SLA 設定',
    numberTips: '請輸入數字',
    slaEmptyTips: '不能為空',
    slaMaximumTips: '不能大於32000',
    createSuccess: 'SLA 已成功新增！',
    editSuccess: 'SLA 已成功修改！',
    deleteSuccess: 'SLA 刪除成功！',
    emptyTips: '未找到相關服務等級協議。',
  },
  // 访客問題管理
  visitorQuestion: {
    visitorQuestionTitle: '訪客問題',
    visitorQuestionBankTitle: '題庫列表',
    searchTips: '請輸入問題',
    visitorQuestionAdd: '新增問題',
    visitorQuestionEdit: '編輯問題',
    visitorQuestionTypeSc: '單選',
    visitorQuestionTypeMc: '複選',
    visitorQuestionTypeIn: '填空',
    visitorQuestionInputtypeTxt: '文本',
    visitorQuestionInputtypeNum: '數字',
    visitorQuestionInputtypeDate: '日期時間',
    visitorQuestionTopicTips: '請輸入題目',
    visitorQuestionTopicTypeTips: '請選擇题目類型',
    visitorQuestionInputTypeTips: '請選擇填空類型',
    visitorQuestionNumTips: '請輸入数字',
    visitorQuestionSeqTips: '請輸入排序號',
    visitorQuestionOption200Tips: '每一項的內容不能超過200字元',
    visitorQuestionOptionalInputTips: '請輸入內容',
    visitorQuestionOptionalTips: '請新增可選項',
    visitorQuestionAddToBank: '新增到題庫',
    createSuccess: '題目已成功新增',
    editSuccess: '題目已成功編輯',
    deleteSuccess: '題目已成功刪除',
    emptyTips: '未找到相關問題。',
  },
  // 访客問題模板
  visitorQuestionTmpl: {
    visitorQuestionTmplTitle: '訪客問題模板',
    visitorQuestionTmplSearchTips: '請輸入模板名稱或描述',
    visitorQuestionTmplName: '模板名稱',
    visitorQuestionTmplDesc: '模板描述',
    visitorQuestionTmplAdd: '新增問題模板',
    visitorQuestionTmplEdit: '編輯問題模板',
    visitorQuestion200Tips: '不能超過200字元',
    valueFormal: '請輸入數字',
    pleaseFillIn: '請填寫',
    pleaseSelect: '請選擇',
    visitorQuestionInputTypeDateTips: '請選擇日期時間',
    visitorQuestionList: '問題列表',
    visitorQuestionAdd: '新增問題',

    visitorQuestionSelect: '從題庫中選擇',

    visitorQuestionSeq: '排序號',
    visitorQuestionTopic: '題目',
    visitorQuestionTopicType: '題目類型',
    visitorQuestionFillType: '填空類型',
    visitorQuestionRequired: '必填？',
    visitorQuestionTmplNameTips: '請輸入問題模板名稱',
    visitorQuestionTmplDescTips: '請輸入問題模板描述',
    createSuccess: '訪客問題模板已成功新增！',
    editSuccess: '訪客問題模板已成功修改！',
    deleteSuccess: '訪客問題模板刪除成功！',
    visitorQuestionOptional: '可選項',
    visitorQuestionAddOptional: '加一項',
    emptyTips: '未找到相關問題模板。',
  },
  // 巡檢監控
  inspMn: {
    title: '巡檢監控',
    hiddenChartLabel: '隱藏圖表',
    todayCheckPntComp: '巡檢單完成率（今日）',
    todayCheckPntCompTips1: '在巡檢單週期的最後一天完成叫準時完成，在最後一天前完成叫提前完成。',
    todayCheckPntCompTips2: '當天完成的單子(除逾時完成外)數量 / 提前完成或準時完成數量 = 當天完成率。',
    todayCheckPntCompTooltip1: '當天完成的單子(除超時完成外)數量',
    todayCheckPntCompTooltip2: '提前完成或準時完成數量',
    checkPntChartTitle: '完成',
    weeklyWo: '工單狀態（最近1週）',
    weeklyInspPntComp: '巡點完成數TOP10（最近1週）',
    eventRpt: '事件報告',
    eventRpt3Day: '3天內發生的新事件報告',
    inspOrder: '巡檢單',
    pendingInspOrder: '待完成的巡檢單',
    skipInspPnt: '今日跳過巡檢點',
    compInspPnt: '今日完成巡檢點',
    wo: '工單',
    pendingWo: '待完成工單',
    executor: '執行人',
    group: '執行組',
    deleteLocation: '該巡檢點已刪除',
    // 工單图表
    chartComp: '完成',
    chartInProgress: '進行中',
    chartonHold: '暫擱置',
    chartExecuted: '待執行',
    chartCancel: '取消',
    // 巡檢狀態
    STATUS_COMP: '完成',
    STATUS_ARRIVE: '到達',
    STATUS_NOT_STARTED: '未開始',
    STATUS_EVENT_RPT: '事件報告',
    STATUS_WO_ORDER: '工單',
    STATUS_SKIP: '跳過',
    STATUS_SKIP_REASON: '跳過原因',
    STATUS_ABNORMAL: '異常',
    STATUS_TIMEOUT: '逾時',
    STATUS_TIMEOUT_TIP: '長時間未到達',
    STATUS_WAIT_FOR_PROCESS: '待審核',
    STATUS_REFUSE: '退回',

    FIXED: '置頂固定',
    UNPIN: '取消固定',
    VIEW_DETAIL: '查看詳細',

    OFFLINE_TIP: '網絡連接已斷開，請檢查您的網絡！',
    ONLINE_TIP: '網絡連接已恢復！',

    SEARCH_TIPS: '請輸入巡檢單描述、單號、執行人或執行組',
    COLOR_LABEL: '巡檢單背景顏色',

    INSP_CUSTOM_THEME_LABEL: '自訂主題',
    INSP_CUSTOM_THEME_RESTORE_LABEL: '還原',
    INSP_STYLE_LABEL: '巡檢單',
    INSP_STYLE_LABEL_BG_CLR: '背景顏色',
    INSP_STYLE_LABEL_MAIN_TITLE_CLR: '主標題文字',
    INSP_STYLE_LABEL_SUBTITLE_CLR: '子標題文字',
    INSP_STYLE_LABEL_SCROLL_BTN_BG_CLR: '滾動按鈕',
    INSP_STYLE_LABEL_SCROLL_BTN_BG_CLR_HOVER: '滾動按鈕懸停',
    INSP_STYLE_LABEL_SCROLL_BTN_ICON_CLR: '滾動按鈕圖示',
    INSP_STYLE_LABEL_SCROLL_BTN_ICON_CLR_HOVER: '滾動按鈕圖示懸停',

    INSNUM: '巡檢單號',
    INSPLANTIME: '表定巡檢時間',
    REVIEWER: '覆核人',
    NODATA: '無資料',
    LOADING: '讀取中...',

    // 安全事件
    mapPoint: '巡點位置',
    SAFETY_INCIDENT_TITLE: '安全事件',
    SAFETY_INCIDENT_RECORDS: '安全事件記錄',
    SAFETY_INCIDENT_NEW_RECORDS: '新記錄',
    SAFETY_INCIDENT_NO_NEW_RECORDS: '暫無新記錄',
    SAFETY_INCIDENT_NO_RECORDS: '暫無記錄',
    SAFETY_INCIDENT_VIEW_ALL: '查看所有',
    SAFETY_INCIDENT_COLLAPSE: '收起',
    SAFETY_INCIDENT_START_DATE: '開始日期',
    SAFETY_INCIDENT_END_DATE: '結束日期',
    SAFETY_INCIDENT_TIME: '時間',
    SAFETY_INCIDENT_ACCOUNT: '帳號名稱',
    SAFETY_INCIDENT_ACCOUNT_PLHD: '請輸入帳號名稱',

    SAFETY_INCIDENT_TYPE: '事件類型',
    SAFETY_INCIDENT_TYPE_ALL: '全部類型',
    SAFETY_INCIDENT_TYPE_LONGNOMOVE: '長時間未移動',

    viewLoc: '查看位置',
    SAFETYVIEWREAD: '已知曉',
  },
  safetyIncident: {
    emptyTips: '未找到相關安全事件。',
  },
  // 库存管理
  invMgt: {
    INVTMGTTITLE: '庫存管理',
    INVTMATTITLE: '庫存材料',
    MATCLEARSEARCH: '清空搜尋',
    MATSEARCHTIPS: '搜尋物料編號/描述',
    ADDMATERIAL: '新增物料',
    INVTDETAIL: '內容',
    MATERIALNUM: '物料編號',
    MATDESCABBR: '描述',
    MATERIALWH: '倉庫',
    MATERIALQTY: '餘量',
    JUMPTOPAGE: '跳到',
    JUMPTOPAGEUNIT: '頁',
    MATSEARCHRESULT: '搜尋記錄',
    MATEMPTYTIPS: '沒有找到相關的庫存物料',
    MATCATEGORY: '物料類別',
    MATSPECIFICATION1: '規格一',
    MATSPECIFICATION2: '規格二',
    MATSPECIFICATION3: '規格三',
    specEnterTips: '請輸入規格',
    editSpecSuccessful: '編輯規格成功',
    editSpecFailed: '編輯規格失敗',
    MATUNIT: '物料單位',
    deleteUnitSuccessful: '刪除物料單位成功',
    deleteUnitFailed: '刪除物料單位失敗',
    deleteSuccess: '物料已成功刪除！',
    MATDESC: '物料描述',
    MATCFMADD: '確認新增',
    MATADDEXIST: '該物料已存在',
    MATSELECTTIPS: '請選擇類別',
    MATSPEC1TIPS: '例如品牌',
    MATSPEC2TIPS: '例如型號',
    MATSPEC3TIPS: '例如顔色',
    MATUNITTIPS: '請輸入單位',
    matUtilMandatoryTips: '請輸入單位',
    utilMandatoryTips: '請輸入單位',
    ADDMATEMPTYTIPS: '請輸入內容',
    MATUNITDELTIPS: '是否刪除該單位？',
  },
  // 库存物料
  invMat: {
    MATRETURNTOLIST: '返回列表',
    MATDETCLASS: '類別',
    MATDETUNIT: '單位',
    MATDETEDIT: '編輯',
    MATDETSAVE: '儲存',
    MATDETCANCEL: '取消',
    MATDETSPEC: '規格',
    MATDETQTY: '餘量',
    MATDETTOTALQTY: '總餘量',
    MATDETDESC: '描述',
    MATDETCOST: '平均成本',
    INVTSTATUS: '庫存情況',
    INVTSEARCHTIPS: '搜尋倉庫/貨架',
    INVTRPORDERNUM: '入庫單/採購單編號',
    INVTPWPRICE: '本次採購/入庫的價格',
    INVTMODIFYREASON: '修改原因',
    INVTRPORDERNUM50WORDS: '不能超過50字元',
    INVTMODIFYREASON50WORDS: '不能超過200字元',

    INVTADD: '新增庫存',
    INVTWHNAMETIPS: '請輸入倉庫名',
    INVTWHNAMETIPSPlh: '請輸入倉庫名',
    INVTSHELF: '貨架',
    INVTSHELFTIPS: '請輸入貨架號',
    INVTSHELFTIPSPlh: '請輸入貨架號',
    INVTCOST: '標準成本',
    INVTEMPTYTIPS: '沒有找到相關的庫存記錄',
    INVTADDQTY: '數量',
    INVTADDCFM: '確認新增',
    INVTCONTINUEADD: '確認並繼續新增',

    INVTADJUST: '調整庫存',
    INVTADJUSTED: '調整後',
    INVTADJUSTBTN: '調整庫存',
    INVTADJUSTQTY: '餘量調整',
    INVTADJUSTCFM: '確認調整',

    MATTISDELTIPS: '是否刪除該物料？',
    INVTISDELTIPS: '是否刪除該庫存？',
    deleteSuccess: '庫存已成功刪除！',
    INVTDELTIPS: '庫存餘量等於 0 時才能刪除',
    TOTALINVTDELTIPS: '總餘量等於 0 時才能刪除',
    WH50WORDS: '倉庫不能超過50字元',
    SHELF50WORDS: '貨架不能超過50字元',
    SPECONE50WORDS: '規格一不能超過50字元',
    SPECTWO50WORDS: '規格二不能超過50字元',
    SPECTHREE50WORDS: '規格三不能超過50字元',
    MATUNIT50WORDS: '單位不能超過50字元',
    DELWHTIPS: '是否刪除該倉庫？',
    DELSHELFTIPS: '是否刪除該貨架？',
    WHNOEMPTYTIPS: `該倉庫有 {str} 等物料，不能刪除`,
    SHELFNOEMPTYTIPS: `該貨架有 {str} 等物料，不能刪除`,

    MATDETISSUANCE: '發料',
    MATDETRETURN: '退料',
    VIEWMATDET: '檢視物料',
  },
  // 物料使用記錄
  matUsageRecord: {
    title: '物料使用記錄',
    ISSUETYPE_ALL: '全部',
    ISSUETYPE_ISSUE: '發放庫存項目',
    ISSUETYPE_RETURN: '返回庫存項目',
    ISSUETYPE_AVGCSTADJ: '平均成本調整',
    ISSUETYPE_CURBALADJ: '當前餘量調整',
    ISSUETYPE_INSERTITEM: '插入新庫存項目',
    ISSUETYPE_RECEIPT: '接收庫存項目',
    MATSELECTTIPS: '請選擇',

    MATUSEDATE: '時間',
    MATWONUM: '工單編號',
    MATDETAILWONUM: '工單編號',
    MATUSESTATUS: '使用情況',
    MATUSEQUANTITY: '數量',

    MATUSAGERECORDDETAIL: '物料使用記錄明細',
    MATUSEDESC: '使用描述',
    MATUSETYPE: '使用類型',
    MATUSECREATEDATE: '使用時間',
    MATUSER: '使用人員',
    MATPATPNAME: '巡檢點名稱',
    MATPATPNUM: '巡檢點編號',
    MATWHNUM: '倉庫編號',
    MATSHELFNAME: '貨架',
    MATSHELFNUM: '貨架編號',

    MAT_ISSUE: '發放庫存項目',
    MAT_BEFORE_ISSUE: '調整前餘量',
    MAT_AFTER_ISSUE: '調整後餘量',

    MAT_RETURN: '返回庫存項目',
    MAT_BEFORE_RETURN: '調整前餘量',
    MAT_AFTER_RETURN: '調整後餘量',

    MAT_AVGCSTADJ: '平均成本調整',
    MAT_BEFORE_AVGCSTADJ: '調整前餘量',
    MAT_AFTER_AVGCSTADJ: '調整後餘量',

    MAT_CURBALADJ: '當前餘量調整',
    MAT_BEFORE_CURBALADJ: '調整前餘量',
    MAT_AFTER_CURBALADJ: '調整後餘量',

    MAT_INSERTITEM: '插入新庫存項目',
    MAT_BEFORE_INSERTITEM: '調整前餘量',
    MAT_AFTER_INSERTITEM: '調整後餘量',

    MAT_RECEIPT: '接收庫存項目',
    MAT_BEFORE_RECEIPT: '調整前餘量',
    MAT_AFTER_RECEIPT: '調整後餘量',

    MATCOST: '成本變動',
    MATNEWCOST: '新平均成本',
    MATOLDCOST: '舊平均成本',
    emptyTips: '未找到相關物料使用記錄。',
  },
  rentPropertyList: {
    title: '物件清單',
    enterSearch: '請輸入物件名稱',
    name: '物件名稱',
    nameTips: '請輸入物件名稱',
    labelType: '物件標籤類型',
    labelTypePlh: '請選擇物件標籤類型',
    labelTypeTips: '請選擇物件標籤類型',
    qrcode: 'QR Code',
    lastDate: '最後交易日期',
    status: '當前狀態',
    statusPlh: '請選擇狀態',
    lent: '已借出',
    available: '可用',
    changeToAvailable: '改為可用',
    lost: '遺失',
    stop: '停用',
    stopped: '停用',
    photo: '照片',
    createTitle: '創建物件',
    editTitle: '編輯物件',
    detailTitle: '物件內容',
    confirmDelete: '請確認是否刪除該物件？',
    createSuccess: '物件已成功創建！',
    editSuccess: '物件已成功編輯！',
    deleteSuccess: '物件已成功刪除！',
    stopSuccess: '物件已成功停用！',
    enableSuccess: '物件已成功改為可用！',
    emptyTips: '未找到相關物件。',
  },
  selectRentalProperty: {
    title: '物件列表',
    selected: '已選擇 {count}',
    selectedProperty: '已選擇的物件',
    selectTips: '請選擇物件',
  },
  lendReturn: {
    title: '借用 / 歸還',
    enterSearch: '請輸入物件名稱',
    num: '借用單編號',
    name: '物件名稱',
    nameTips: '請選擇物件',
    labelType: '物件標籤類型',
    lendPurpose: '借用理由',
    lendPurposePlh: '請輸入或選擇借用理由',
    lendPurposeTips: '請輸入或選擇借用理由',
    lendReason: '借用理由',
    setCommonPurpose: '設為常用理由',
    propertyPhoto: '物件照片',
    transactionDate: '借用日期',
    transactionDateTips: '請選擇借用日期',
    selectDate: '選擇日期',
    expectedReturnDate: '預計歸還日期',
    expectedReturnDateTips: '請選擇預計歸還日期',
    editReturnDateReason: '修改理由',
    editReasonPh: '請輸入修改預計歸還日期理由',
    editReasonTips: '請輸入修改預計歸還日期理由',
    recipient: '借用人姓名',
    recipientTips: '請輸入或選擇借用人',
    recipientPlh: '請輸入或選擇借用人',
    selectRecipientTitle: '借用人列表',
    recipientId: '借用人證件號碼',
    recipientIdTips: '請輸入借用人證件號碼',
    recipientPhone: '借用人手機號碼',
    recipientPhoneTips: '請輸入借用人手機號碼',
    handler: '經手人',
    handlerTips: '請輸入經手人',
    handlerId: '經手人證件號碼',
    handlerIdTips: '請輸入經手人證件號碼',
    handlerIdRuleTips: '只能輸入數字、英文或特殊符號，特殊符號僅支持#-',
    returnStatus: '歸還狀態',
    toBeReturned: '已借出',
    returned: '已歸還',
    return: '歸還',
    confirmReturnTips: '確認歸還？',
    returnSuccess: '物件已成功歸還！',
    createTitle: '創建借用單',
    editTitle: '編輯借用單',
    detailTitle: '借用單內容',
    continueAdd: '繼續新增',
    createSuccess: '借用單已成功創建！',
    editSuccess: '借用單已成功編輯！',
    emptyTips: '未找到相關借用單。',
    maxLentTime: '最多可借用時長',
    day: '天',
    hour: '小時',
    maxLentTimeUnitTips: '請選擇時間單位',
    maxLentTimeUnitPlh: '請選擇時間單位',
  },
  disposalOrder: {
    title: '報廢處置單',
    reason: '處置原因/理由',
    reasonPlh: '請輸入或選擇處置原因/理由',
    reasonTips: '請輸入或選擇處置原因/理由',
    disposalReason: '處置理由',
    rentalOrder: '借用單',
    confirmer: '確認人',
    confirmerTips: '請選擇確認人',
    selectConfirmerTitle: '確認人列表',
    attachment: '附件',
    disposalPhoto: '報廢處置單照片',
    status: '狀態',
    confirmed: '已確認',
    unconfirmed: '未確認',
    cancelled: '已取消',
    createTitle: '創建報廢處置單',
    editTitle: '編輯報廢處置單',
    detailTitle: '報廢處置單內容',
    cancelDisposalOrder: '取消報廢處置單',
    cancelDisposalOrderTips: '請確認是否取消報廢處置單？',
    cancelDisposalOrderSuccess: '報廢處置單已成功取消！',
    confirmDisposalOrder: '確認報廢處置單',
    confirmDisposalOrderTips: '請確認是否確認報廢處置單？',
    confirmDisposalOrderSuccess: '報廢處置單已成功確認！',
    confirmTime: '確認時間',
    cancelTime: '取消時間',
    createSuccess: '報廢處置單已成功創建！',
    editSuccess: '報廢處置單已成功編輯！',
    emptyTips: '未找到相關報廢處置單。',
    attachmentUploadFaild: '附件上傳失敗！',
    isExistLendOrder: '該物件創建了借用單，單號為：{num}',
    attachmentTypeTips: '只能上傳 .jpg、.jpeg、.png、.pdf 格式的檔案',
  },
  patrolTrack: {
    inspectionPointList: '巡檢點列表',
    track: '追蹤',
  },
  guide: {
    previous: '上一步',
    next: '下一步',
    goOpen: '去打開',
    goCreate: '去創建',
    goPreview: '去預覽',
    goView: '去查看',
    goEnable: '去啓用',
    goSet: '去設置',
    goAdd: '去添加',
    finish: '完成',
    ok: '確定',
    understand: '瞭解',
    continueCreate: '繼續創建',
    nextStage: '進入下一階段',
  }
}

export default lang